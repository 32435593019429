import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-block"
}
const _hoisted_2 = { class: "block-heading" }
const _hoisted_3 = { class: "block-body" }
const _hoisted_4 = { class: "outer-col" }
const _hoisted_5 = { class: "inner-row" }
const _hoisted_6 = { class: "inner-col inner-col-name" }
const _hoisted_7 = { class: "inner-col inner-col-data" }
const _hoisted_8 = { class: "inner-row" }
const _hoisted_9 = { class: "inner-col inner-col-name" }
const _hoisted_10 = { class: "inner-col inner-col-data letter-spacing-one-pixel" }
const _hoisted_11 = { class: "outer-col" }
const _hoisted_12 = { class: "inner-row" }
const _hoisted_13 = { class: "inner-col inner-col-name" }
const _hoisted_14 = { class: "inner-col inner-col-data" }
const _hoisted_15 = { class: "inner-row" }
const _hoisted_16 = { class: "inner-col inner-col-name" }
const _hoisted_17 = { class: "inner-col inner-col-data" }
const _hoisted_18 = { class: "outer-col" }
const _hoisted_19 = { class: "inner-row" }
const _hoisted_20 = { class: "inner-col inner-col-name" }
const _hoisted_21 = { class: "inner-col inner-col-data" }
const _hoisted_22 = { class: "inner-row" }
const _hoisted_23 = { class: "inner-col inner-col-name" }
const _hoisted_24 = { class: "inner-col inner-col-data letter-spacing-one-pixel" }
const _hoisted_25 = {
  key: 1,
  class: "table-block"
}
const _hoisted_26 = { class: "block-heading" }
const _hoisted_27 = { class: "block-body" }
const _hoisted_28 = { class: "outer-col" }
const _hoisted_29 = { class: "inner-row" }
const _hoisted_30 = { class: "inner-col inner-col-name" }
const _hoisted_31 = { class: "inner-col inner-col-data" }
const _hoisted_32 = { class: "outer-col" }
const _hoisted_33 = { class: "inner-row" }
const _hoisted_34 = { class: "inner-col inner-col-name" }
const _hoisted_35 = { class: "inner-col inner-col-data letter-spacing-one-pixel" }
const _hoisted_36 = { class: "outer-col" }
const _hoisted_37 = { class: "inner-row" }
const _hoisted_38 = { class: "inner-col inner-col-name" }
const _hoisted_39 = { class: "inner-col inner-col-data" }
const _hoisted_40 = { class: "outer-col" }
const _hoisted_41 = { class: "inner-row" }
const _hoisted_42 = { class: "inner-col inner-col-name" }
const _hoisted_43 = { class: "inner-col inner-col-data letter-spacing-one-pixel" }

export function render(_ctx, _cache) {
  return (!_ctx.isLegalProfile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.formatDate(_ctx.source.purchase_date_at)), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('profile_text_bonus_before')) + ":", 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.source.before_bonus || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('profile_text_bill')) + ":", 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.source.invoice_id || '-'), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('profile_text_bonus_after')) + ":", 1),
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.source.after_bonus), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('profile_text_sum')) + ":", 1),
              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.source.invoice_amount_gsp || 0) + " грн.", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('profile_text_get_wasted')) + ":", 1),
              _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.source.received_bonus || 0) + "/" + _toDisplayString(_ctx.source.spent_bonus || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t('profile_text_period')) + ":", 1),
              _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.formatDate(_ctx.source.started_at_period,'MM/YYYY')) + " - " + _toDisplayString(_ctx.formatDate(_ctx.source.ended_at_period,'MM/YYYY')), 1)
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_25, [
        _createElementVNode("h4", _hoisted_26, _toDisplayString(_ctx.formatDate(_ctx.source.purchase_date_at)) + ", " + _toDisplayString(_ctx.source.owner_name), 1),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('profile_text_get')) + ":", 1),
              _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.source.received_bonus || 0), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.$t('profile_text_bill')) + ":", 1),
              _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.source.invoice_id || '-'), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.$t('profile_text_sum')) + ":", 1),
              _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.source.invoice_amount_gsp || 0) + " грн.", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_40, [
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.$t('profile_text_period')) + ":", 1),
              _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.formatDate(_ctx.source.started_at_period,'MM/YYYY')) + " - " + _toDisplayString(_ctx.formatDate(_ctx.source.ended_at_period,'MM/YYYY')), 1)
            ])
          ])
        ])
      ]))
}