import { NavigationGuardNext } from 'vue-router'; // 'Route was here'
import jwt from 'jsonwebtoken';

export const Validator = {
  phoneNumber: (phoneNumber: string, isLowCheck: boolean = false) =>
    isLowCheck
      ? /^(\+38|38|)(0\d{9})$/.test(phoneNumber)
      : /^(\+38)(0\d{9})$/.test(phoneNumber),
  clientCard: (clientCard: string) => /^\d{22}$/.test(clientCard),
  email: (email: string) =>
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email
    ),
  inn: (inn: string) => /^(\d{10}|\d{12})$/.test(inn),
  edrpou: (edrpou: string) => /^\d{8}$/.test(edrpou),
  smsCode: (code: string) => /^[0-9]+$/.test(code),
};

export const Formatter = {
  phone: (phoneNumber: string) =>
    /^(\+38|38)/.test(phoneNumber)
      ? phoneNumber[0] === '+'
        ? phoneNumber
        : `+${phoneNumber}`
      : `+38${phoneNumber}`,
};

export const Actions = {
  timeoutActionEvent: (dispatch: any, type: string) => {
    dispatch(type);
  },
};

export const Router = {
  checkAuth: (to: any, from: any, next: any) => {
    if (to.meta.checkAuth) {
      let token = localStorage.getItem('access_token');
      if (!token) {
        next({
          name: 'WelcomePage',
        });
      } else {
        const decodedToken: any = jwt.decode(token) || { exp: 0 };
        const currentTime: number = new Date().getTime();
        if (currentTime < decodedToken.exp * 1000) {
          next();
        } else {
          localStorage.removeItem('access_token');
          next({
            name: 'WelcomePage',
          });
        }
      }
    } else {
      next();
    }
  },
};

export interface Params {
  [key: string]: any;
}

export const ApiUtils = {
  toQueryString: (params: Params) => {
    let queryString = Object.keys(params)
      .filter((key) => params[key])
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&');
    return queryString ? `?${queryString}` : '';
  },
};

export const Sort = {
  ascNumber: (fieldName: string) => (a: any, b: any) =>
    +a[fieldName] - +b[fieldName],
};
