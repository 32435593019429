
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

@Options({})
export default class DialogYesNo extends Vue {
  @Prop({ type: String, default: '' })
  readonly 'activator-button-label'!: string;
  @Prop({ type: String, default: '' }) readonly 'yes-button-label'!: string;
  @Prop({ type: String, default: '' }) readonly 'no-button-label'!: string;
  @Prop({ type: String, default: '' }) readonly title!: string;
  @Prop({ type: String, default: '' }) readonly message!: string;
  @Prop({ type: Boolean, default: false })
  readonly 'yes-button-disabled'!: boolean;
  @Prop({ type: String, default: () => 'error' })
  readonly 'yes-button-color'!: string;

  showDialog = false;

  t(key: string) {
    return this.$t(key);
  }

  dialogOpen() {
    this.showDialog = true;
  }

  onButtonYesClick() {
    this.showDialog = false;
    this.$emit('confirm');
  }

  dialogClose() {
    this.showDialog = false;
    this.$emit('close');
  }
}
