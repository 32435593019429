import { GetterTree } from 'vuex';
import { StatusState } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<StatusState, RootState> = {
  activeStatuses(state, _, rootState) {
    //@ts-ignore
    // console.log(rootState.user.info.nextBonusesGeneral)
    //@ts-ignore
    let userBonuses = rootState.user.info.sumInvoiceGeneral;
    let sorted = [...state.list].sort(
      (a, b) => +a.criteria_bonus - +b.criteria_bonus
    );
    // console.log(sorted)

    let sortedWithIndex = sorted.map((item, index) => ({
      index: index + 1,
      ...item,
    }));
    // console.log(sortedWithIndex)
    let currentBonusIndex = null;

    // console.log(sortedWithIndex)
    for (let i = 0; i < sortedWithIndex.length; i++) {
      // console.log(sorted[i]);
      if (sortedWithIndex[i].criteria_bonus > userBonuses) {
        currentBonusIndex = i;
        break;
      }
    }
    // console.log(currentBonusIndex)
    let result: any[] = [];
    if (sortedWithIndex.length && userBonuses >= 0) {
      if (!currentBonusIndex) {
        result = [sortedWithIndex[sortedWithIndex.length - 1]];
      } else {
        result = currentBonusIndex
          ? sortedWithIndex.slice(currentBonusIndex - 1, currentBonusIndex + 2)
          : [];
      }
      // console.log(result)
    }

    // console.log(result);
    // console.log(currentBonusIndex? sortedWithIndex.slice(currentBonusIndex - 1, currentBonusIndex + 2): [])
    return result;
  },

  percentOfCurrentStatus(state, stateGetters, rootState) {
    // console.log(getters.activeStatuses)
    //@ts-ignore
    let userBonuses = rootState.user.info.sumInvoiceGeneral;
    if (stateGetters.activeStatuses.length) {
      let percent = 0;
      if (stateGetters.activeStatuses.length > 1) {
        percent =
          ((userBonuses - stateGetters.activeStatuses[0].criteria_bonus) /
            (stateGetters.activeStatuses[1].criteria_bonus -
              stateGetters.activeStatuses[0].criteria_bonus)) *
          100;
      } else {
        percent = 100;
      }

      // console.log(percent.toFixed(0))
      return percent.toFixed(0);
    } else {
      return 0;
    }
  },
};
