import { GetterTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<UserState, RootState> = {
  isLegalProfile(state) {
    return state.info.legalPerson === 'legal';
  },

  cardholdersCells(state) {
    const dataInfo = [state.info, ...state.info.cardholders];
    const leng = dataInfo.length; //5 //3 //1 //2
    // let rowCount = leng > 1? leng - (leng % 2) / 2 + 1: 1; // 3 2
    const cells = [];
    for (let i = 0; i < leng; i += 2) {
      const row = [];
      // if(dataInfo[i]){ row.push(dataInfo[i]) }
      row.push(dataInfo[i]);
      if (dataInfo[i + 1]) {
        row.push(dataInfo[i + 1]);
      }
      cells.push(row);
    }
    return cells;
  },

  companiesCells(state) {
    const companiesList: Array<any> = [];
    const id = state.info.company.companyId ? state.info.company.companyId : 0;
    companiesList[id] = [state.info];

    const companiesOrder = [id];

    if (state.info.cardholders && state.info.cardholders.length > 0) {
      state.info.cardholders.map((item) => {
        const id = item.company
          ? item.company.companyId
            ? item.company.companyId
            : 0
          : 0;
        if (typeof companiesList[id] === 'undefined') {
          companiesList[id] = [];
          companiesOrder.push(id);
        }

        companiesList[id].push(item);
      });
    }

    const result: Array<any> = [];
    companiesOrder.forEach((companyId) => {
      const cells = [];
      const dataInfo = companiesList[companyId];
      const length = dataInfo.length;
      for (let i = 0; i < length; i += 2) {
        const row = [];
        row.push({
          selected: false,
          client: dataInfo[i],
        });

        if (dataInfo[i + 1]) {
          row.push({
            selected: false,
            client: dataInfo[i + 1],
          });
        }
        cells.push(row);
      }
      result.push({
        selected: false,
        cells,
      });
    });

    return result;
  },

  cardholdersBarcodes(state) {
    let cardholders: any = [];
    if (state.info.cardholders.length) {
      cardholders = [...state.info.cardholders];
    }
    return [state.info, ...cardholders]
      .filter((cardholder) => cardholder.barcode && cardholder.id)
      .map(({ id, barcode }) => ({ id, barcode }));
  },
};
