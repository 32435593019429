
// @ is an alias to /src
import { Prop, Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { UserStateComponent } from '@/store/modules/user/types';
import { Formatter, Validator } from '@/utils';

@Options({})
export default class AgreePage extends Vue {
  @State('user')
  user!: UserStateComponent;

  @Action('getProfile', { namespace: 'user' })
  getProfile: any;

  @Getter('cardholdersCells', { namespace: 'user' })
  cardholdersCells: any;

  @Getter('isLegalProfile', { namespace: 'user' })
  isLegalProfile!: string;

  @Action('confirmTerms', { namespace: 'user' })
  confirmTerms: any;

  agreeCheckbox: any = false;
  agreeCheckboxError: any = false;

  rulesPageLink: string = process.env.VUE_APP_RULES_PAGE_LINK || '#';

  async beforeMount() {
    await this.getProfile();
  }

  @Watch('agreeCheckbox', { deep: true })
  handleAgreeCheckboxChange(check: boolean) {
    if (check) {
      this.agreeCheckboxError = false;
    }
  }
  // get cardholdersCells() {

  //     let dataInfo = [this.user.info, ...this.user.info.cardholders];
  //     let leng = dataInfo.length; //5 //3 //1 //2
  //     let rowCount = leng > 1? leng - (leng % 2) / 2 + 1: 1; // 3 2
  //     let cells = [];
  //     for (let i = 0; i < leng; i+=2) {
  //         let row = [];
  //         if(dataInfo[i]){ row.push(dataInfo[i]) }
  //         if(dataInfo[i + 1]){ row.push(dataInfo[i + 1]) }
  //         cells.push(row);
  //     }
  //     return cells;
  // }

  async handleConfirm() {
    if (this.agreeCheckbox) {
      await this.confirmTerms();
      if (!this.user.hasError) {
        this.$router.push({ path: '/account/profile' });
      }
    } else {
      this.agreeCheckboxError = true;
    }
  }

  // beforeUnmount(){
  //     console.log('ffesf')
  // }

  phoneFormatter(phone: string) {
    return Validator.phoneNumber(phone) ? phone : Formatter.phone(phone);
  }
  // @Prop({ default: {} }) readonly info!: {};
  // @Prop(Function) readonly handleOK: () => {} | undefined;
}
