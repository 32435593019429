
// @ is an alias to /src
import { Vue, Options } from 'vue-class-component';
import { Action, Getter, State } from 'vuex-class';
import InvoiceItem from '@/components/InvoiceItem';
import { InvoiceStateComponent } from '@/store/modules/invoice/types';
import Paginate from 'vuejs-paginate';
import Check from '@/components/CustomComponents/Check';

@Options({
  name: 'loyalty-checks',
  components: {
    Paginate,
    Check,
  },
})
export default class Checks extends Vue {
  @State('invoice')
  invoice!: InvoiceStateComponent;

  @Action('getList', { namespace: 'invoice' })
  getInvoiceList: any;

  invoiceItem: any = InvoiceItem;
  invoiceList: any[] = [];
  invoicePage = 1;

  created() {
    this.getInvoiceList({
      params: { page: this.invoicePage, with_relations: 'details' },
    }).then(() => {
      this.invoiceList = this.invoice.list;
    });
  }

  async changeInvoicePageCallback(page: number) {
    this.invoicePage = page;
    await this.getInvoiceList({
      params: {
        page: this.invoicePage,
      },
    });
    this.invoiceList = this.invoice.list;
  }

  loadMoreInvoices() {
    this.invoicePage += 1;
    this.getInvoiceList({ params: { page: this.invoicePage } }).then(() => {
      this.invoiceList.push(...this.invoice.list);
    });
  }
}
