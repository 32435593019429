
// @ is an alias to /src
import { Vue, Options } from 'vue-class-component';
import { Action, State } from 'vuex-class';
import { ModalStateComponent } from '@/store/modules/modal/types';
import { UserStateComponent } from '@/store/modules/user/types';
import vSelect from 'vue-select';

@Options({
  name: 'cards-modal',
  components: {
    vSelect,
  },
})
export default class CardsModal extends Vue {
  @State('modal')
  modal!: ModalStateComponent;
  @State('user')
  user!: UserStateComponent;

  @Action('hideCardsModal', { namespace: 'modal' })
  hideCardsModal: any;
  @Action('showSuccessModal', { namespace: 'modal' })
  showSuccessModal: any;
  @Action('blockCards', { namespace: 'user' })
  blockCards: any;
  @Action('getProfile', { namespace: 'user' })
  getProfile: any;
  get cards_list() {
    return this.user.info.cardholders;
  }
  card_reducer(item: any) {
    return item.id;
  }
  cards: number[] = [];
  choose_all() {
    const user_cards = this.user.info.cardholders;
    if (this.cards.length) {
      this.cards = [];
      return;
    }
    const all_cards_id: Array<any> = user_cards.map((item) => item.id);
    this.cards = [...all_cards_id];
  }
  async handle_cards() {
    await this.blockCards({
      ids: this.cards,
      block: this.modal.cardsModal.block,
    });
    const message = this.modal.cardsModal.block
      ? this.$t('settings_text_cards_blocked')
      : this.$t('settings_text_cards_unblocked');

    this.cards = [];
    this.hideCardsModal();
    this.showSuccessModal({
      title: this.$t('settings_modal_title_ready'),
      message,
    });
    await this.getProfile();
  }
  hide() {
    this.cards = [];
    this.hideCardsModal();
  }
  get heading() {
    const block_text = this.$t('loyalty.settings.modal.headings.block_card');
    const unblock_text = this.$t(
      'loyalty.settings.modal.headings.unblock_card'
    );
    const is_blocking = this.modal.cardsModal.block;
    return is_blocking ? block_text : unblock_text;
  }
  get send_button_text() {
    const is_blocking = this.modal.cardsModal.block;
    const block_text = this.$t('loyalty.settings.modal.buttons.block');
    const unblock_text = this.$t('loyalty.settings.modal.buttons.unblock');
    return is_blocking ? block_text : unblock_text;
  }
}
