import { ActionTree } from 'vuex';
import { FeedbackState } from './types';
import { RootState } from '@/store/types';
import Api from '@/api';

export const actions: ActionTree<FeedbackState, RootState> = {
  sendFeedback({ commit }, { text }): any {
    commit('setPending');
    return Api.feedback.sendFeedback(text).then(
      (response) => {
        // const payload = response && response.data;
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
};
