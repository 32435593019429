import { ActionTree } from 'vuex';
import { InvoiceState } from './types';
import { RootState } from '@/store/types';
import Api from '@/api';

export const actions: ActionTree<InvoiceState, RootState> = {
  getList({ commit }, { params }): any {
    commit('setPending');
    return Api.invoice.getList(params).then(
      (response) => {
        const payload = response && response.data;
        commit('unsetPending');
        commit('unsetError');
        commit('setList', payload);
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
};
