
// @ is an alias to /src
import { Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { Action, Getter, State } from 'vuex-class';
import { ModalStateComponent } from '@/store/modules/modal/types';
import { VoucherStateComponent } from '@/store/modules/voucher/types';
import { VoucherGenerateRequestData } from '@/api/types';

import vSelect from 'vue-select';

@Options({
  components: {
    vSelect,
  },
})
export default class VoucherGenerateModal extends Vue {
  @State('modal')
  modal!: ModalStateComponent;
  @State('voucher')
  voucher!: VoucherStateComponent;
  @Getter('isLegalProfile', { namespace: 'user' })
  isLegalProfile!: boolean;
  @Getter('cardholdersBarcodes', { namespace: 'user' })
  cardholdersBarcodes!: any;
  @Action('hideVoucherModal', { namespace: 'modal' })
  hideVoucherModal: any;
  @Action('showSuccessModal', { namespace: 'modal' })
  showSuccessModal: any;

  @Action('generateVoucher', { namespace: 'voucher' })
  generateVoucher: any;

  @Action('getProfile', { namespace: 'user' })
  getProfile: any;

  @Getter('errorCode', { namespace: 'voucher' })
  errorCode!: string;

  @Getter('sortedFaceValues', { namespace: 'voucher' })
  sortedFaceValues!: any;

  selectedFaceValue: any = null;
  selectedCardholder: any = null;

  @Watch('selectedFaceValue', { deep: true })
  checkChangeFaceValue(val: any) {
    if (val) {
      this.validErrorMessage = '';
    }
  }

  @Watch('selectedCardholder', { deep: true })
  checkCardholderValue(val: any) {
    if (val) {
      this.validErrorMessage = '';
    }
  }

  validErrorMessage: string = '';

  errorHandler(errorCode: string) {
    switch (errorCode) {
      case 'VALID':
        this.validErrorMessage = this.$t('voucher_modal_validation_error');
        break;
      case 'ERR6110':
        this.validErrorMessage = this.$t('voucher_modal_not_enough_bonuses');
        break;
      case 'ERR6111':
        this.validErrorMessage = this.$t('error-voucher-can_not_deactivate');
        break;
      case 'ERR6112':
        this.validErrorMessage = this.$t('voucher_modal_error_is_used');
        break;
      case 'ERR6113':
        this.validErrorMessage = this.$t('voucher_modal_error_is_deactivated');
        break;
      case 'ERR6114':
        this.validErrorMessage = this.$t(
          'voucher_modal_error_can_not_get_campains'
        );
        break;
      case 'ERR6115':
        this.validErrorMessage = this.$t('voucher_modal_error_token_error');
        break;
      case 'ERR6116':
        this.validErrorMessage = this.$t(
          'voucher_modal_error_can_not_parse_json'
        );
        break;
      case 'ERR6017':
        this.validErrorMessage = this.$t(
          'voucher_modal_error_unknown_status_code'
        );
        break;
      case 'ERR6018':
        this.validErrorMessage = this.$t('voucher_modal_error_invalid_token');
        break;
      default:
        this.validErrorMessage = this.$t('voucher_modal_server_error');
        break;
    }
  }

  validForGen() {
    return this.isLegalProfile
      ? !!(this.selectedFaceValue && this.selectedCardholder)
      : !!this.selectedFaceValue;
  }

  async gen() {
    if (this.validForGen()) {
      let data: VoucherGenerateRequestData = {
        faceValue: this.selectedFaceValue.id,
      };
      if (this.isLegalProfile) {
        data.userId = this.selectedCardholder.id;
      }
      await this.generateVoucher({ data });
      if (this.errorCode || this.voucher.hasError) {
        this.errorHandler(this.errorCode);
      } else {
        this.showSuccessModal({
          title: this.$t('settings_modal_title_ready'),
          message: this.$t('voucher_modal_success'),
        });
        this.hideVoucherModal();
        this.selectedFaceValue = null;
        this.selectedCardholder = null;
        this.getProfile();
      }
    } else {
      this.errorHandler('VALID');
    }
  }
}
