import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bordered-wrapper bordered-wrapper--bottom" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-5" }
const _hoisted_4 = { class: "stack--bottom--xl mb-4 mb-lg-0" }
const _hoisted_5 = { class: "entity-key entity-key--xl" }
const _hoisted_6 = { class: "text--gray text-xl" }
const _hoisted_7 = { class: "text--black heading-4" }
const _hoisted_8 = { class: "entity-key entity-key--xl" }
const _hoisted_9 = { class: "text--gray text-xl" }
const _hoisted_10 = { class: "text--black heading-4" }
const _hoisted_11 = { class: "entity-key entity-key--xl" }
const _hoisted_12 = { class: "text--gray text-xl" }
const _hoisted_13 = { class: "text--black heading-4" }
const _hoisted_14 = { class: "col-lg-7" }
const _hoisted_15 = { class: "stack--bottom--xl" }
const _hoisted_16 = { class: "entity-key entity-key--xl" }
const _hoisted_17 = { class: "text--gray text-xl" }
const _hoisted_18 = { class: "text--black heading-4" }
const _hoisted_19 = { class: "entity-key entity-key--xl" }
const _hoisted_20 = { class: "text--gray text-xl" }
const _hoisted_21 = { class: "text--black heading-4" }
const _hoisted_22 = { class: "entity-key entity-key--xl" }
const _hoisted_23 = { class: "text--gray text-xl" }
const _hoisted_24 = { class: "text--black heading-4" }
const _hoisted_25 = {
  key: 1,
  class: "page-subheading"
}
const _hoisted_26 = {
  key: 2,
  class: "stack--bottom--lg"
}
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-lg-5" }
const _hoisted_29 = { class: "entity-key entity-key--xl" }
const _hoisted_30 = { class: "text--gray text-xl" }
const _hoisted_31 = { class: "text--primary text-xl medium" }
const _hoisted_32 = { class: "col-lg-7" }
const _hoisted_33 = { class: "entity-key entity-key--xl" }
const _hoisted_34 = { class: "text--gray text-xl" }
const _hoisted_35 = { class: "text--primary text-xl medium" }
const _hoisted_36 = { class: "stack--top--lg mt-3" }
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-lg-5 mb-3" }
const _hoisted_39 = { class: "entity-key entity-key--xl" }
const _hoisted_40 = { class: "text--gray text-xl" }
const _hoisted_41 = { class: "text--primary text-xl medium" }
const _hoisted_42 = { class: "col-lg-7 mb-3" }
const _hoisted_43 = { class: "entity-key entity-key--xl" }
const _hoisted_44 = { class: "text--gray text-xl" }
const _hoisted_45 = { class: "text--primary text-xl medium" }
const _hoisted_46 = { class: "col-lg-5 mb-3" }
const _hoisted_47 = { class: "entity-key entity-key--xl" }
const _hoisted_48 = { class: "text--gray text-xl" }
const _hoisted_49 = { class: "text--primary text-xl medium" }
const _hoisted_50 = { class: "col-lg-7 mb-3" }
const _hoisted_51 = { class: "entity-key entity-key--xl" }
const _hoisted_52 = { class: "text--gray text-xl" }
const _hoisted_53 = { class: "text--primary text-xl medium" }
const _hoisted_54 = { class: "col-lg-5" }
const _hoisted_55 = { class: "entity-key entity-key--xl" }
const _hoisted_56 = { class: "text--gray text-xl" }
const _hoisted_57 = { class: "text--primary text-xl medium" }

export function render(_ctx, _cache) {
  const _component_alert = _resolveComponent("alert")
  const _component_expansion_panel = _resolveComponent("expansion-panel")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('profile_text_name')) + ":", 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.name), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('profile_text_surname')) + ":", 1),
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.surname), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('profile_text_phone')) + ":", 1),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.phone), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('profile_text_email')) + ":", 1),
              _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.email), 1)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('profile_text_cardnumber')) + ":", 1),
              _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.barcode), 1)
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('profile_text_center_info')) + ":", 1),
              _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.store_name), 1)
            ])
          ])
        ])
      ])
    ]),
    (_ctx.isLegalProfile)
      ? (_openBlock(), _createBlock(_component_alert, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.is_cardholders)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_25, _toDisplayString(_ctx.$t('cardholders_data')), 1))
      : _createCommentVNode("", true),
    (_ctx.is_cardholders)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.info.cardholders, (cardholder, cardholder_index) => {
            return (_openBlock(), _createBlock(_component_expansion_panel, {
              class: "full-row full-row--xxl background--smoky",
              key: 'cardholder' + cardholder_index
            }, {
              body: _withCtx(() => [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('cardholders_card_number')), 1),
                      _createElementVNode("span", _hoisted_31, "№ " + _toDisplayString(cardholder.barcode), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$t('cardholders_tc_data')) + ":", 1),
                      _createElementVNode("span", _hoisted_35, _toDisplayString(cardholder.store_name), 1)
                    ])
                  ])
                ])
              ]),
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("div", _hoisted_39, [
                        _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t('cardholders_name')) + ":", 1),
                        _createElementVNode("span", _hoisted_41, _toDisplayString(cardholder.firstName), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("div", _hoisted_43, [
                        _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t('cardholders_surname')) + ":", 1),
                        _createElementVNode("span", _hoisted_45, _toDisplayString(cardholder.lastName), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.$t('cardholders_limit')) + ":", 1),
                        _createElementVNode("span", _hoisted_49, _toDisplayString(cardholder.limit), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_50, [
                      _createElementVNode("div", _hoisted_51, [
                        _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.$t('cardholders_phone')) + ":", 1),
                        _createElementVNode("span", _hoisted_53, _toDisplayString(cardholder.phone), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_54, [
                      _createElementVNode("div", _hoisted_55, [
                        _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.$t('cardholders_mail')) + ":", 1),
                        _createElementVNode("span", _hoisted_57, _toDisplayString(cardholder.email), 1)
                      ])
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}