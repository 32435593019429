import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "profile-content" }
const _hoisted_2 = { class: "content-header" }
const _hoisted_3 = { class: "heading" }
const _hoisted_4 = { class: "content-body" }
const _hoisted_5 = { class: "information-table" }
const _hoisted_6 = { class: "table-heading" }
const _hoisted_7 = { class: "table-content" }
const _hoisted_8 = {
  key: 0,
  class: "table-row"
}
const _hoisted_9 = { class: "table-col name-col" }
const _hoisted_10 = { class: "table-col data-col" }
const _hoisted_11 = {
  key: 1,
  class: "table-row"
}
const _hoisted_12 = { class: "table-col name-col" }
const _hoisted_13 = { class: "table-col data-col" }
const _hoisted_14 = {
  key: 2,
  class: "table-row"
}
const _hoisted_15 = { class: "table-col name-col" }
const _hoisted_16 = { class: "table-col data-col" }
const _hoisted_17 = {
  key: 3,
  class: "table-row"
}
const _hoisted_18 = { class: "table-col name-col" }
const _hoisted_19 = { class: "table-col data-col" }
const _hoisted_20 = ["placeholder"]
const _hoisted_21 = {
  key: 0,
  class: "error-message margin-bottom-xl"
}
const _hoisted_22 = { class: "feedback-btn-wrapper" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('main_menu_feedback')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('feedback_title_feedback')), 1),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.user.info.firstName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('feedback_text_name')) + ":", 1),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.user.info.firstName), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.info.lastName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('feedback_text_surname')) + ":", 1),
                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.user.info.lastName), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.info.middleName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('feedback_text_middlename')) + ":", 1),
                _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.user.info.middleName), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.info.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('feedback_text_email')) + ":", 1),
                _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.user.info.email), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([{ error: _ctx.validationErrorMessage }, 'feedback-textarea-wrapper'])
      }, [
        _withDirectives(_createElementVNode("textarea", {
          class: "feedback-textarea",
          onInput: _cache[0] || (_cache[0] = (...args) => (_ctx.inputFeedback && _ctx.inputFeedback(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.feedbackText) = $event)),
          name: "feedback_message",
          id: "feedback_message",
          cols: "30",
          rows: "10",
          placeholder: _ctx.$t('feedback_text_write')
        }, null, 40, _hoisted_20), [
          [_vModelText, _ctx.feedbackText]
        ]),
        (_ctx.validationErrorMessage)
          ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.validationErrorMessage), 1))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.send && _ctx.send(...args))),
          class: _normalizeClass([{ disabled: _ctx.feedback.isPending }, 'primary-btn', 'send-feedback-btn'])
        }, _toDisplayString(!_ctx.feedback.isPending? _ctx.$t('feedback_button_leave') : _ctx.$t('feedback_button_leave_pending')), 3)
      ])
    ])
  ]))
}