
const enabledGtmState = 'enabled';

import { Vue, Options } from 'vue-class-component';

@Options({})
export default class App extends Vue {
  get isGTMEnabled() {
    return this.isGTMEnabledHandler(process.env.VUE_APP_GTM_STATE);
  }
  mounted() {
    if (this.isGTMEnabled) {
      this.addGTMScripts();
    }
  }

  isGTMEnabledHandler(value: any = 'false') {
    return value.toLowerCase() === enabledGtmState ? true : false;
  }

  addGTMScripts() {
    // GTM to the <head>
    const startComment = document.createComment('Google Tag Manager');
    const headScript = document.createElement('script');
    headScript.innerHTML =
      "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-TCTG5C6');";
    const endComment = document.createComment('End Google Tag Manager');
    let items = [startComment, headScript, endComment];
    items.forEach((element) => {
      document.head.appendChild(element);
    });
    // GTM to after <body>
    const startNoscript = document.createComment(
      'Google Tag Manager (noscript)'
    );
    const noscriptItem = document.createElement('noscript');
    const frame = document.createElement('iframe');
    frame.setAttribute(
      'src',
      'https://www.googletagmanager.com/ns.html?id=GTM-TCTG5C6'
    );
    frame.setAttribute('height', '0');
    frame.setAttribute('width', '0');
    frame.setAttribute('style', 'display:none;visibility:hidden');
    noscriptItem.append(frame);
    const endNoscript = document.createComment(
      'End Google Tag Manager (noscript)'
    );
    let itemsBottom = [startNoscript, noscriptItem, endNoscript];
    itemsBottom.forEach((element) => {
      document.body.appendChild(element);
    });
  }
}
