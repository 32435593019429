import { MutationTree } from 'vuex';
import { NotificationState } from './types';

export const mutations: MutationTree<NotificationState> = {
  setList(state, payload) {
    state.list = payload.data;
    state.pagination = {
      current_page: payload.meta.current_page,
      last_page: payload.meta.last_page,
      total: payload.meta.total,
      per_page: payload.meta.per_page,
      from: payload.meta.from,
      to: payload.meta.to,
    };
  },
};
