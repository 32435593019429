// import Vue from 'vue';
import { createI18n } from 'vue-i18n';
// import ru from './files/ru.json';
import ua from './files/ua.json';
// Vue.use(VueI18n);

// function loadLocaleMessages () {
//   const locales = require.context('./files', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   console.log(locales)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages;
// }
const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ua',
  globalInjection: true,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ua',
  messages: {
    // ru,
    ua,
  }, //loadLocaleMessages()
});
export default i18n;
