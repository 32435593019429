import { AxiosInstance } from 'axios';

export default ($axios: AxiosInstance) => ({
  getList() {
    return $axios.get('/type-discount');
  },
  changeType(type_discount_id: number) {
    return $axios.put('/type-discount', { type_discount_id });
  },
});
