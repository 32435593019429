
// @ is an alias to /src
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { Action } from 'vuex-class';

@Options({
  name: 'card',
})
export default class Card extends Vue {
  @Prop({ type: Object, default: {}, required: true }) 'item'!: {
    id: number;
    cardIsBlocked: boolean;
    barcode: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    limit: string | null;
    bonuses: string | null;
    bonusesGeneral: string | null;
    spentBonuses: string | null;
    currentBonuses: string | null;
    nextBonuses: string | null;
    spentBonusesGeneral: string | null;
  };
  @Prop({ type: String, default: null }) 'color'!: string;
  @Prop({ type: Boolean, default: false }) 'is_owner'!: boolean;

  @Action('showLimitModal', { namespace: 'modal' })
  showLimitModal: any;

  get is_blocked() {
    return this.item.cardIsBlocked;
  }
  get barcode() {
    return this.item.barcode;
  }
  get bonuses() {
    return this.item.bonuses;
  }
  get limit() {
    return this.item.limit;
  }
  get bonuses_spent() {
    return this.item.spentBonuses;
  }
  get bonuses_current() {
    return this.item.currentBonuses;
  }
  get bonuses_next() {
    return this.item.nextBonuses;
  }
  get name() {
    const name = this.item.firstName;
    const surname = this.item.lastName;
    const patronymic = this.item.middleName;
    return `${surname} ${name} ${patronymic}`;
  }
  get popperHelp(): any {
    return {
      content: this.$t('components.card.edit_limit_hint'),
      theme: 'dropdown',
      offset: [0, 16],
      placement: 'left',
    };
  }
}
