import { ActionTree } from 'vuex';
import { AuthState, AuthRequestData, AuthResponseData } from './types';
import { RootState } from '@/store/types';
import Api from '@/api';

export const actions: ActionTree<AuthState, RootState> = {
  // login
  sendSMS({ commit }, { loginData }): any {
    commit('setPending');
    return Api.auth.authBySMS(loginData).then(
      (response) => {
        const payload: AuthResponseData = response && response.data;
        commit('sendSMSDone', payload);
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('sendSMSError');
        commit('setError', error.response.data);
      }
    );
  },
  // login, smsCode
  authWithSMSCode({ commit }, { authData }): any {
    commit('setPending');
    return Api.auth.authGetToken(authData).then(
      (response) => {
        const payload = response && response.data;
        // commit('authWithSMSCodeDone', payload);
        if (payload.access_token) {
          localStorage.setItem('access_token', payload.access_token);
        }
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },

  logout({ commit }): any {
    if (sessionStorage.getItem('isShowedModal')) {
      sessionStorage.removeItem('isShowedModal');
    }
    if (localStorage.getItem('access_token')) {
      localStorage.removeItem('access_token');
    }
  },
};
