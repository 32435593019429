import { MutationTree } from 'vuex';
import { UserState } from './types';

export const mutations: MutationTree<UserState> = {
  getProfileDone(state, payload) {
    const legalData: any = {};
    const nextPeriodDiscount = payload.data.next_period_type_discount;
    if (payload.data.client_type === 'legal') {
      legalData.legalPerson = payload.data.client_type;
      // store: '',
      // legalData.isAgree = !!payload.data.is_agree;
      // legalData.isBlocked = !!payload.data.is_blocked;
      legalData.company = {
        companyId: payload.data.company.id,
        companyName: payload.data.company.company_name,
        area: payload.data.company.area,
        district: payload.data.company.district,
        town: payload.data.company.town,
        house: payload.data.company.house,
        street: payload.data.company.street,
        email: payload.data.company.company_email,
        phone: payload.data.company.owner_phone,
      };
      legalData.cardholders =
        payload.data.cardholders && payload.data.cardholders.length
          ? payload.data.cardholders.map((ch: any) => ({
              barcode: ch.barcode,
              id: ch.id,
              clientType: ch.client_type,
              // store: string;
              firstName: ch.first_name,
              middleName: ch.middle_name,
              lastName: ch.last_name,
              phone: ch.phone,
              email: ch.email,
              isAgree: !!ch.is_agree,
              isBlocked: !!ch.is_blocked,
              cardIsBlocked: !!ch.card_is_blocked,
              cardholder_id: ch.cardholder_id,
              customer_id: ch.customer_id,
              limit: ch.limit,
              sumInvoiceGeneral: ch.sum_invoice_general,
              bonusesGeneral: ch.bonuses_general,
              bonuses: ch.bonuses,
              currentBonusesGeneral: ch.current_bonuses_general,
              currentBonuses: ch.current_bonuses,
              spentBonusesGeneral: ch.spent_bonuses_general,
              spentBonuses: ch.spent_bonuses,
              nextBonusesGeneral: ch.next_bonuses_general,
              nextBonuses: ch.next_bonuses,
              store_name: ch.store_name,
              company: {
                companyId: ch.company ? ch.company.id : null,
                companyName: ch.company ? ch.company.company_name : '',
              },
            }))
          : [];
    }
    state.info = {
      ...state.info,
      firstName: payload.data.first_name,
      id: payload.data.id,
      clientType: payload.data.client_type,
      lastName: payload.data.last_name,
      middleName: payload.data.middle_name,
      email: payload.data.email,
      barcode: payload.data.barcode,
      state: payload.data.state,
      phone: payload.data.phone,
      emailVerifiedAt: payload.data.email_verified_at,
      isAgree: !!payload.data.is_agree,
      isBlocked: !!payload.data.is_blocked,
      cardIsBlocked: !!payload.data.card_is_blocked,
      cardholder_id: payload.data.cardholder_id,
      customer_id: payload.data.customer_id,
      limit: payload.data.limit,
      sumInvoiceGeneral: payload.data.sum_invoice_general,
      bonusesGeneral: payload.data.bonuses_general,
      bonuses: payload.data.bonuses,
      currentBonusesGeneral: payload.data.current_bonuses_general,
      currentBonuses: payload.data.current_bonuses,
      spentBonusesGeneral: payload.data.spent_bonuses_general,
      spentBonuses: payload.data.spent_bonuses,
      nextBonusesGeneral: payload.data.next_bonuses_general,
      nextBonuses: payload.data.next_bonuses,
      discountType: {
        created_at: payload.data.type_discount.created_at,
        id: payload.data.type_discount.id,
        key: payload.data.type_discount.key,
        name: payload.data.type_discount.name,
        updated_at: payload.data.type_discount.updated_at,
      },
      nextPeriodDiscountType: nextPeriodDiscount
        ? {
            created_at: nextPeriodDiscount.created_at,
            id: nextPeriodDiscount.id,
            key: nextPeriodDiscount.key,
            name: nextPeriodDiscount.name,
            updated_at: nextPeriodDiscount.updated_at,
          }
        : null,
      status:
        payload.data.status && !Array.isArray(payload.data.status)
          ? payload.data.status
          : {
              criteria_bonus: 0,
            },
      nextStatus:
        payload.data.next_status && !Array.isArray(payload.data.next_status)
          ? payload.data.next_status
          : {
              criteria_bonus: 0,
            },
      current_period_start: payload.data.current_period_start,
      current_period_end: payload.data.current_period_end,
      unread_notifications: payload.data.unread_notifications,
      store_name: payload.data.store_name,

      // {
      //     children_id: null,
      //     client_type: "individual",
      //     coefficient_discount: 2.51,
      //     created_at: "1994-01-11T22:36:57.000000Z",
      //     criteria_bonus: 86707.24,
      //     disabled_coefficient_discount: 0,
      //     disabled_discount: 0,
      //     discount: 6,
      //     dnr: "1736527177",
      //     duration: "2020-12-22T01:50:42.000000Z",
      //     id: 117,
      //     index: 30,
      //     is_active: 1,
      //     is_default: null,
      //     name: "Ludwig Roberts IV",
      //     start_at: "1994-12-03T04:21:50.000000Z",
      //     updated_at: "2020-09-01T13:28:04.000000Z"
      // },//[...payload.data.status],
      ...legalData,
    };
  },
};
