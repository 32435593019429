
// @ is an alias to /src
import { Vue, Options } from 'vue-class-component';
import { Action, State } from 'vuex-class';
import { UserStateComponent } from '@/store/modules/user/types';
import { ModalStateComponent } from '@/store/modules/modal/types';
import Navigation from '@/components/UiComponents/Navigation';
import ErrorModal from '@/components/ErrorModal';
import SuccessModal from '@/components/SuccessModal';
import EmailModal from '@/components/EmailModal';
import VoucherGenerateModal from '@/components/VoucherGenerateModal';
import LimitChangeModal from '@/components/LimitChangeModal';
import DiscountTypeModal from '@/components/DiscountTypeModal';
import WelcomeModal from '@/components/WelcomeModal';
import CardsModal from '@/components/CardsModal';

@Options({
  components: {
    Navigation,
    ErrorModal,
    SuccessModal,
    EmailModal,
    VoucherGenerateModal,
    LimitChangeModal,
    DiscountTypeModal,
    WelcomeModal,
    CardsModal,
  },
})
export default class PageLayout extends Vue {
  @State('user')
  user!: UserStateComponent;
  @State('modal')
  modal!: ModalStateComponent;
  @Action('getProfile', { namespace: 'user' })
  getProfile: any;
  @Action('showWelcomeModal', { namespace: 'modal' })
  showWelcomeModal: any;
  @Action('logout', { namespace: 'auth' })
  logout: any;
  rulesPageLink: string = process.env.VUE_APP_RULES_PAGE_LINK || '#';

  beforeMount() {
    this.getProfile();
  }

  get username() {
    const name = this.user.info.firstName;
    const surname = this.user.info.lastName;
    return `${name} ${surname}`;
  }

  handleLogout() {
    this.logout();
    this.$router.push({ path: '/login/person' });
  }
}
