
// @ is an alias to /src
import { Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { Getter } from 'vuex-class';
// import InvoiceItem from "@/components/InvoiceItem";
// import DialogYesNo from "@/components/DialogYesNo";
// import MetroCheckbox from "@/components/MetroCheckbox";
// import ExpansionPanel from "@/components/UiComponents/ExpansionPanel";
// import Check from "@/components/CustomComponents/Check";
// import { UserStateComponent } from "@/store/modules/user/types";
// import { StatusStateComponent } from "@/store/modules/status/types";
// import { InvoiceStateComponent } from "@/store/modules/invoice/types";
// import { ModalStateComponent } from "@/store/modules/modal/types";
/*import VirtualList from 'vue3-virtual-scroll-list';*/
/*const VirtualList = require('vue3-virtual-scroll-list');*/
import Paginate from 'vuejs-paginate';
// import moment from "moment";
// import { Voucher, VoucherStateComponent } from "@/store/modules/voucher/types";

@Options({
  components: {
    Paginate,
    // VirtualList,
    // "metro-checkbox": MetroCheckbox,
    // DialogYesNo,
    // ExpansionPanel,
    // Check,
  },
})
export default class LoyaltyPage extends Vue {
  // @State("user")
  // user!: UserStateComponent;
  //
  // @State("voucher")
  // voucher!: VoucherStateComponent;
  //
  // @State("invoice")
  // invoice!: InvoiceStateComponent;
  //
  // @State("status")
  // status!: StatusStateComponent;
  //
  // @State("modal")
  // modal!: ModalStateComponent;
  //

  // @Action("getProfile", { namespace: "user" })
  // getProfile: any;
  //
  // @Action("blockCards", { namespace: "user" })
  // blockCards: any;
  //
  // @Action("getList", { namespace: "voucher" })
  // getVoucherList: any;
  //
  // @Action("getList", { namespace: "status" })
  // getStatusesList: any;
  //
  // @Action("getList", { namespace: "invoice" })
  // getInvoiceList: any;
  //
  // @Action("getFaceValueList", { namespace: "voucher" })
  // getFaceValueList: any;
  //
  // @Action("generateVoucher", { namespace: "voucher" })
  // generateVoucher: any;
  //
  // @Action("deactivateVoucher", { namespace: "voucher" })
  // deactivateVoucher: any;
  //
  // @Action("showVoucherModal", { namespace: "modal" })
  // showVoucherModal: any;
  //
  // @Action("hideVoucherModal", { namespace: "modal" })
  // hideVoucherModal: any;
  //
  // @Action("showLimitModal", { namespace: "modal" })
  // showLimitModal: any;
  //
  // @Action("showBonusesModal", { namespace: "modal" })
  // showBonusesModal: any;
  //
  // @Action("showSuccessModal", { namespace: "modal" })
  // showSuccessModal: any;
  //
  // @Action("showErrorModal", { namespace: "modal" })
  // showErrorModal: any;
  //
  // @Getter("errorCode", { namespace: "user" })
  // errorCode!: string;
  //
  // @Getter("errorCode", { namespace: "voucher" })
  // voucherErrorCode!: string;
  //
  // @Getter("activeStatuses", { namespace: "status" })
  // activeStatuses!: any[];
  //
  // @Getter("percentOfCurrentStatus", { namespace: "status" })
  // percentOfCurrentStatus!: any[];
  //
  @Getter('isLegalProfile', { namespace: 'user' })
  isLegalProfile!: string;
  //
  // @Getter("companiesCells", { namespace: "user" })
  // companiesCells!: any;
  //
  // invoiceItem: any = InvoiceItem;
  // invoiceList: any[] = [];
  // invoicePage: number = 1;

  // genDateSort: string = '';
  // useDateSort: string = '';
  // sortField: string = "";
  // sortOrder: string = "";
  // selectedFaceValue: any = null;
  // selectedCardholder: any = null;
  // currentPage: number = 1;
  // activeTab: string = "basic-info";
  //
  // rulesPageLink: string = process.env.VUE_APP_RULES_PAGE_LINK || "#";
  //
  // companiesCellsLocal = [];
  //
  // selectedVouchersIndexes = [];
  //
  // get selectedVouchers(): Array<Voucher> {
  //   return this.voucher.list.filter((voucher) =>
  //     this.checkVoucherSelected(voucher.id)
  //   );
  // }

  // get invoiceExtraPropsData() {
  //     return ({ isLegalProfile: this.isLegalProfile })
  // }

  // @Watch("voucher.list", { deep: true })
  // async selectedVouchersIndexesClear() {
  //   this.selectedVouchersIndexes.splice(0, this.selectedVouchersIndexes.length);
  // }
  //
  // @Watch("activeTab", { deep: true, immediate: true })
  // changeTabWatcher(activeTab: string) {
  //   if (activeTab === "vouchers") {
  //     this.getVoucherList({ params: { page: 1 } });
  //     this.getFaceValueList({});
  //   }
  //   if (activeTab === "basic-info" || activeTab === "invoices") {
  //     this.getStatusesList({}).then(() => {
  //       // console.log(this.activeStatuses);
  //     });
  //     this.getInvoiceList({
  //       params: { page: this.invoicePage, with_relations: "details" },
  //     }).then(() => {
  //       this.invoiceList = this.invoice.list;
  //     });
  //     // this.getFaceValueList({ params: { per_page: 50 } })
  //   }
  // }
  //
  // @Watch("companiesCells", { deep: true })
  // async companiesCellsChange(val: boolean) {
  //   this.companiesCellsLocal = this.companiesCells;
  // }
  //
  // @Watch("modal.voucherModal.show", { deep: true })
  // async closeVoucherModal(val: boolean) {
  //   if (!val) {
  //     await this.getVoucherList({
  //       params: {
  //         page: this.currentPage,
  //         order_field: this.sortField,
  //         order_method: this.sortOrder,
  //       },
  //     });
  //   }
  // }

  // get selected(): string {
  //   let hasSelectedCards = false;
  //   let hasSelectedBlocked = false;
  //
  //   this.companiesCellsLocal.forEach((company: any) => {
  //     if (!company.cells) {
  //       return;
  //     }
  //
  //     company.cells.forEach((row: any) => {
  //       row.forEach((item: any) => {
  //         if (!item.selected) {
  //           return;
  //         }
  //
  //         if (item.client.clientType !== "cardholder") {
  //           return;
  //         }
  //
  //         if (item.client.cardIsBlocked) {
  //           hasSelectedBlocked = true;
  //         } else {
  //           hasSelectedCards = true;
  //         }
  //       });
  //     });
  //   });
  //
  //   if (hasSelectedCards && hasSelectedBlocked) {
  //     return "mixed";
  //   }
  //
  //   if (hasSelectedCards) {
  //     return "cards";
  //   }
  //
  //   if (hasSelectedBlocked) {
  //     return "blocked";
  //   }
  //
  //   return "";
  // }

  // mounted() {
  //   this.companiesCellsLocal = this.companiesCells;
  // }
  //
  // async changeSort(sortName: string) {
  //   if (this.sortField !== sortName) {
  //     this.sortField = sortName;
  //     this.sortOrder = "asc";
  //   } else {
  //     this.sortField = sortName;
  //     switch (this.sortOrder) {
  //       case "asc":
  //         this.sortOrder = "desc";
  //         break;
  //       default:
  //         this.sortOrder = "asc";
  //         break;
  //     }
  //   }
  //
  //   await this.getVoucherList({
  //     params: {
  //       page: this.currentPage,
  //       order_field: this.sortField,
  //       order_method: this.sortOrder,
  //     },
  //   });
  // }
  //
  // async changePageCallback(page: number) {
  //   this.currentPage = page;
  //   await this.getVoucherList({
  //     params: {
  //       page: this.currentPage,
  //       order_field: this.sortField,
  //       order_method: this.sortOrder,
  //     },
  //   });
  // }
  //
  // async changeInvoicePageCallback(page: number) {
  //   this.invoicePage = page;
  //   await this.getInvoiceList({
  //     params: {
  //       page: this.invoicePage,
  //     },
  //   });
  //   this.invoiceList = this.invoice.list;
  // }
  //
  // formatDate(date: string) {
  //   return date ? moment(date).format("DD.MM.YYYY") : "-";
  // }
  //
  // changeTab(tabName: string) {
  //   this.activeTab = tabName;
  // }
  //
  // loadMoreInvoices() {
  //   this.invoicePage += 1;
  //   this.getInvoiceList({ params: { page: this.invoicePage } }).then(() => {
  //     this.invoiceList.push(...this.invoice.list);
  //   });
  // }
  //
  // checkVoucherSelected(voucherId: number): boolean {
  //   //@ts-ignore
  //   return this.selectedVouchersIndexes.includes(voucherId);
  // }
  //
  // checkVoucherSelectedAll(): boolean {
  //   for (let key in this.voucher.list) {
  //     const voucher = this.voucher.list[key];
  //     //@ts-ignore
  //     if (!this.selectedVouchersIndexes.includes(voucher.id)) {
  //       return false;
  //     }
  //   }
  //
  //   return true;
  // }
  //
  // onVoucherCheck(voucherId: number) {
  //   //@ts-ignore
  //   const index = this.selectedVouchersIndexes.indexOf(voucherId);
  //   if (index === -1) {
  //     //@ts-ignore
  //     this.selectedVouchersIndexes.push(voucherId);
  //     return;
  //   }
  //
  //   this.selectedVouchersIndexes.splice(index, 1);
  // }
  //
  // onVoucherCheckAll(selected: boolean) {
  //   this.voucher.list.forEach((voucher) => {
  //     //@ts-ignore
  //     const includes = this.selectedVouchersIndexes.includes(voucher.id);
  //     if (selected === includes) {
  //       return true;
  //     }
  //
  //     this.onVoucherCheck(voucher.id);
  //   });
  // }
  //
  // printVouchers(vouchers: Array<Voucher>) {
  //   if (!vouchers.length) {
  //     return;
  //   }
  //
  //   let html =
  //     '<style type="text/css">' +
  //     " p { letter-spacing: 0.8px; font-size: 20px; font-family: Courier }" +
  //     " .print-voucher-container { margin-bottom: 85px; page-break-inside: avoid }" +
  //     "</style>";
  //
  //   vouchers.forEach((voucherItem: Voucher) => {
  //     const parts = voucherItem.epc.split(":");
  //     let barcode = parts.pop();
  //     if (!barcode) {
  //       return;
  //     }
  //     barcode = barcode.replace(/\./g, "");
  //
  //     const url =
  //       "https://generator.barcode.metro-link.com/?bcid=databarexpandedstacked&text=(255)" +
  //       barcode +
  //       "&scaleX=2&scaleY=1&segments=8&alttext=(255)" +
  //       barcode +
  //       "&backgroundcolor=FFFFFF&paddingheight=5&paddingwidth=5";
  //
  //     const voucherEndDate =
  //       voucherItem.created_at >= this.user.info.current_period_start
  //         ? this.user.info.current_period_end
  //         : this.user.info.current_period_start;
  //
  //     const clientBarcode = this.isLegalProfile
  //       ? voucherItem.client.barcode
  //       : this.user.info.barcode;
  //     html +=
  //       '<div class="print-voucher-container">' +
  //       `<img src="${url}" alt="voucher barcode" />` +
  //       `<br><br><br>` +
  //       `<p>${this.$t("voucher_table_card")}: ${clientBarcode}</p>` +
  //       `<p>${this.$t("voucher_table_campaign")}: ${
  //         voucherItem.face_value && voucherItem.face_value.amount
  //           ? voucherItem.face_value.amount
  //           : "-"
  //       }</p>` +
  //       `<p>${this.$t("voucher_table_enddate")}: ${this.formatDate(
  //         voucherEndDate
  //       )}</p>` +
  //       "</div>";
  //   });
  //
  //   const iframe = document.createElement("iframe");
  //   iframe.setAttribute(
  //     "style",
  //     "position:absolute;width:0;height:0;top:-100%;left:-100%"
  //   );
  //   iframe.classList.add("voucher-print-iframe");
  //
  //   const el = document.body.appendChild(iframe);
  //   if (!el) {
  //     return;
  //   }
  //   const printWindow = el.contentWindow;
  //   if (printWindow === null) {
  //     return;
  //   }
  //   if (!el.contentDocument && !el.contentWindow) {
  //     return;
  //   }
  //   //@ts-ignore
  //   const printDocument = el.contentDocument || el.contentWindow.document;
  //   printDocument.open();
  //   printDocument.write(html);
  //   printDocument.close();
  //
  //   iframe.onload = function () {
  //     printWindow.print();
  //   };
  // }
  //
  // async deactivateVoucherClick(voucher: Voucher) {
  //   await this.deactivateVoucher(voucher.id);
  //
  //   if (this.voucherErrorCode) {
  //     this.showErrorModal({
  //       title: this.$t("voucher_deactivate_error"),
  //       message: "",
  //     });
  //     return;
  //   }
  //
  //   this.getVoucherList({ params: { page: 1 } });
  //   this.getProfile();
  // }
  //
  // onSelectCompanyChange(company: any) {
  //   if (!company.cells) {
  //     return;
  //   }
  //
  //   company.cells.forEach((row: any) => {
  //     row.forEach((item: any) => {
  //       if (item.client.clientType !== "cardholder") {
  //         return;
  //       }
  //
  //       this.$set(item, "selected", company.selected);
  //     });
  //   });
  // }
  //
  // onSelectClientChange(company: any, client: any) {
  //   if (!company.cells) {
  //     return;
  //   }
  //
  //   this.$set(company, "selected", true);
  //
  //   company.cells.forEach((row: any) => {
  //     row.forEach((item: any) => {
  //       if (item.client.clientType !== "cardholder") {
  //         return;
  //       }
  //
  //       if (!item.selected) {
  //         company.selected = false;
  //         this.$set(company, "selected", false);
  //
  //         return false;
  //       }
  //     });
  //   });
  // }

  // getSelectedCardsIds(block: boolean): Array<number> {
  //   const selectedIds: Array<number> = [];
  //
  //   this.companiesCellsLocal.forEach((company: any) => {
  //     if (!company.cells) {
  //       return;
  //     }
  //
  //     company.cells.forEach((row: any) => {
  //       row.forEach((item: any) => {
  //         if (!item.selected) {
  //           return;
  //         }
  //         if (item.client.cardIsBlocked === block) {
  //           return;
  //         }
  //         if (item.client.clientType !== "cardholder") {
  //           return;
  //         }
  //
  //         selectedIds.push(item.client.id);
  //       });
  //     });
  //   });
  //
  //   return selectedIds;
  // }

  // async blockUnblockSingleCard(id: number, block: boolean) {
  //   await this.blockCards({ ids: [id], block });
  //   if (this.errorCode || this.user.hasError) {
  //     const title = block
  //       ? this.$t("settings_error_block_card")
  //       : this.$t("settings_error_unblock_card");
  //     this.showErrorModal({ title, message: "" });
  //     return;
  //   }
  //
  //   const message = block
  //     ? this.$t("settings_text_card_blocked")
  //     : this.$t("settings_text_card_unblocked");
  //   this.showSuccessModal({
  //     title: this.$t("settings_modal_title_ready"),
  //     message,
  //   });
  //   await this.getProfile();
  // }

  // async blockUnblockSomeCards(block: boolean) {
  //   const ids = this.getSelectedCardsIds(block);
  //
  //   await this.blockCards({ ids, block });
  //   if (this.errorCode || this.user.hasError) {
  //     const title = block
  //       ? this.$t("settings_error_block_cards")
  //       : this.$t("settings_error_unblock_cards");
  //     this.showErrorModal({ title, message: "" });
  //     return;
  //   }
  //
  //   const message = block
  //     ? this.$t("settings_text_cards_blocked")
  //     : this.$t("settings_text_cards_unblocked");
  //   this.showSuccessModal({
  //     title: this.$t("settings_modal_title_ready"),
  //     message,
  //   });
  //   await this.getProfile();
  // }
}
