import { MutationTree } from 'vuex';

export interface PendingState {
  isPending: boolean;
}

const mutations: MutationTree<PendingState> = {
  setPending(state, payload) {
    state.isPending = true;
  },
  unsetPending(state, payload) {
    state.isPending = false;
  },
};

export default () => ({
  state: {
    isPending: false,
  },
  getters: {},
  actions: {},
  mutations,
});
