import { MutationTree, GetterTree } from 'vuex';

interface ErrorItem {
  status: string;
  code: string;
  title: string;
  detail: string;
  source: {
    pointer: string;
  };
}

export interface ErrorState {
  hasError: boolean;
  errors: ErrorItem[];
}

const mutations: MutationTree<ErrorState> = {
  setError(state, payload) {
    state.hasError = true;
    if (payload.errors && payload.errors.length) {
      state.errors = payload.errors;
    } else {
      state.errors = [];
    }
  },
  unsetError(state, payload) {
    state.hasError = false;
    state.errors = [];
  },
};

export const getters: GetterTree<ErrorState, ErrorState> = {
  errorCode(state): string {
    const { errors } = state;
    if (errors && errors.length) {
      return errors[0].code;
    } else {
      return '';
    }
  },
};

export default () => ({
  state: {
    hasError: false,
    errors: [],
  },
  getters,
  actions: {},
  mutations,
});
