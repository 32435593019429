
// import Vue from "vue";
import { Action, Getter } from 'vuex-class';
import { Vue, Options } from 'vue-class-component';
import Promocode from '@/components/CustomComponents/Promocode';

@Options({
  name: 'promo-codes',
  components: {
    Promocode,
  },
})
export default class Check extends Vue {
  @Action('getList', { namespace: 'promocode' })
  getPromocodes: any;

  @Getter('list', { namespace: 'promocode' })
  promocodes: any;

  // LOCAL GETTERS
  options = {
    order: [
      {
        title: this.$t('loyalty.promocodes.forms.order.asc'),
        value: 'asc',
      },
      {
        title: this.$t('loyalty.promocodes.forms.order.desc'),
        value: 'desc',
      },
    ],
  };
  filters = {
    order_field: 'id',
    order_method: 'asc',
  };

  created() {
    this.getPromocodes({ params: this.filters });
  }
}
