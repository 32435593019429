// import Vue from 'vue';
import Vuex, { StoreOptions, createStore } from 'vuex';
import { RootState } from './types';
import auth from './modules/auth';
import user from './modules/user';
import feedback from './modules/feedback';
import voucher from './modules/voucher';
import modal from './modules/modal';
import invoice from './modules/invoice';
import status from './modules/status';
import notifications from './modules/notifications';
import discount from './modules/discount';
import promocode from './modules/promocode';

// Vue.use(Vuex);

// const store: StoreOptions<RootState> = {
//   state: {
//     version: '1.0.0' // a simple property
//   },
//   modules: {
//     auth,
//     user,
//     feedback,
//     modal,
//     voucher,
//     invoice,
//     status,
//     notifications,
//     discount,
//     promocode,
//   }
// };

// export const store = createStore<RootState> ({
//   state: {
//     version: '1.0.0' // a simple property
//   },
//   modules: {
//     auth,
//     user,
//     feedback,
//     modal,
//     voucher,
//     invoice,
//     status,
//     notifications,
//     discount,
//     promocode,
//   }
// });

const store = createStore<RootState>({
  state: {
    version: '1.0.0', // a simple property
  },
  modules: {
    auth,
    user,
    feedback,
    modal,
    voucher,
    invoice,
    status,
    notifications,
    discount,
    promocode,
  },
});

export default store;
// export default Vuex.createStore(<RootState>(store));
