import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AuthState } from './types';
import { RootState } from '../../types';
import combainer from '@/store/mixins/vuexCombainer';
import errorHandler from '@/store/mixins/errorHandler';
import pendingHandler from '@/store/mixins/pendingHandler';

export const state: AuthState = {
  timerSeconds: null,
  attempts: null,
};

const namespaced: boolean = true;

const auth = combainer(
  {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  },
  errorHandler(),
  pendingHandler()
);

export default auth;
