import Vue from 'vue';
// import VueRouter, { RouteConfig } from "vue-router";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import WelcomePage from '@/views/WelcomePage';
import ComingSoonPage from '@/views/ComingSoonPage';
import LoginPage from '@/views/LoginPage';
import PageLayout from '@/layouts/PageLayout';
import FeedbackPage from '@/views/FeedbackPage';
import NotificationsPage from '@/views/NotificationsPage';
import ProfilePage from '@/views/ProfilePage';
import LoyaltyPage from '@/views/LoyaltyPage';
import AgreePage from '@/views/AgreePage';
import LandingPage from '@/views/LandingPage';
import NotFoundPage from '@/views/NotFoundPage';

// PROFILE PAGE CHILD ROUTES
import PersonalData from '@/views/ProfilePage/PersonalData';
import CompanyData from '@/views/ProfilePage/CompanyData';

// LOYALTY PAGE CHILD ROUTES
import Basic from '@/views/LoyaltyPage/Basic';
import Settings from '@/views/LoyaltyPage/Settings';
import Checks from '@/views/LoyaltyPage/Checks';
import Vouchers from '@/views/LoyaltyPage/Vouchers';
import Promocodes from '@/views/LoyaltyPage/Promocodes';
import { Router } from '@/utils';

// Vue.use(VueRouter);

export const routes: Array<RouteRecordRaw> = [
  // { path: '/', redirect: '/coming_soon' },
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/coming_soon',
    name: 'ComingSoonPage',
    component: ComingSoonPage,
  },
  {
    path: '/welcome',
    name: 'WelcomePage',
    component: WelcomePage,
  },
  {
    path: '/login/:accountType',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/agree/loyalty',
    name: 'AgreePage',
    component: AgreePage,
  },
  {
    path: '/account',
    name: 'PageLayout',
    component: PageLayout,
    children: [
      {
        path: 'notifications',
        name: 'NotificationsPage',
        component: NotificationsPage,
        meta: {
          checkAuth: true,
        },
      },
      {
        path: 'feedback',
        name: 'FeedbackPage',
        component: FeedbackPage,
        meta: {
          checkAuth: true,
        },
      },
      {
        path: 'profile',
        name: 'ProfilePage',
        redirect: { name: 'PersonalData' },
        component: ProfilePage,
        children: [
          {
            path: 'personal_data',
            name: 'PersonalData',
            component: PersonalData,
          },
          {
            path: 'company_data',
            name: 'CompanyData',
            component: CompanyData,
          },
        ],
        meta: {
          checkAuth: true,
        },
      },
      {
        path: 'loyalty',
        name: 'LoyaltyPage',
        redirect: { name: 'LoyaltyPageBasic' },
        component: LoyaltyPage,
        meta: {
          checkAuth: true,
        },
        children: [
          {
            path: 'basic',
            name: 'LoyaltyPageBasic',
            component: Basic,
          },
          {
            path: 'settings',
            name: 'LoyaltyPageSettings',
            component: Settings,
          },
          {
            path: 'promo-codes',
            name: 'LoyaltyPagePromo-codes',
            component: Promocodes,
          },
          {
            path: 'invoices',
            name: 'LoyaltyPageChecks',
            component: Checks,
          },
          {
            path: 'vouchers',
            name: 'LoyaltyPageVouchers',
            component: Vouchers,
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundPage,
  },
];

// const router = new VueRouter({
//   linkActiveClass: "active",
//   linkExactActiveClass: "exact-active",

//   mode: "history",
//   base: process.env.BASE_URL || "/",
//   routes,
// });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
});

router.beforeEach(Router.checkAuth);

export default router;
