
// @ is an alias to /src
import { Vue, Options } from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { UserStateComponent } from '@/store/modules/user/types';

@Options({
  components: {},
})
export default class FeedbackPage extends Vue {
  @State('user')
  user!: UserStateComponent;

  @State('feedback')
  feedback!: UserStateComponent;

  @Action('sendFeedback', { namespace: 'feedback' })
  sendFeedback: any;

  @Action('showSuccessModal', { namespace: 'modal' })
  showSuccessModal: any;

  @Getter('errorCode', { namespace: 'feedback' })
  errorCode!: string;

  feedbackText: string = '';
  // hasValidationError: boolean = false;
  validationErrorMessage: string = '';

  validateForm() {
    let isValid = true;
    if (!this.feedbackText) {
      isValid = false;
    }
    return isValid;
  }

  inputFeedback() {
    if (this.validationErrorMessage) {
      // this.hasValidationError = false;
      this.validationErrorMessage = '';
    }
  }

  setError(errorCode: string) {
    switch (errorCode) {
      case 'ERR1000':
        this.validationErrorMessage = this.$t('feedback_error_email');
        break;

      default:
        this.validationErrorMessage = this.$t('general_auth_error');
        break;
    }
  }

  async send() {
    if (this.validateForm()) {
      await this.sendFeedback({ text: this.feedbackText });
      if (!this.feedback.hasError) {
        this.showSuccessModal({
          title: this.$t('feedback_modal_title'),
          message: this.$t('feedback_modal_text'),
        });
        this.feedbackText = '';
      } else {
        this.setError(this.errorCode);
      }
    } else {
      // this.hasValidationError = true;
      this.validationErrorMessage = this.$t('feedback_error_required');
    }
  }
}
