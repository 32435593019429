import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "check-item" }
const _hoisted_2 = { class: "check-item__main" }
const _hoisted_3 = { class: "text--primary" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text--gray" }
const _hoisted_6 = { class: "entity-key--md" }
const _hoisted_7 = { class: "text--gray" }
const _hoisted_8 = { class: "text--black medium" }
const _hoisted_9 = {
  key: 0,
  class: "stack--bottom--lg"
}
const _hoisted_10 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_check_item = _resolveComponent("check-item")
  const _component_check_details = _resolveComponent("check-details")
  const _component_expansion_panel = _resolveComponent("expansion-panel")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.check.owner_name) + " ", 1),
        (_ctx.isLegal)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(_ctx.$t('invoice_item_owner')) + ")", 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatDate(_ctx.check.purchase_date_at)), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('invoice_item_number')) + ":", 1),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.check.invoice_id), 1)
      ])
    ]),
    _createVNode(_component_expansion_panel, null, {
      body: _withCtx(() => [
        _createVNode(_component_check_item, { check: _ctx.check }, null, 8, ["check"])
      ]),
      content: _withCtx(() => [
        (_ctx.isCheckDetails)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.check.details, (details, details_index) => {
                return (_openBlock(), _createBlock(_component_check_details, {
                  key: 'details' + details_index,
                  details: details
                }, null, 8, ["details"]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('no_data')), 1))
      ]),
      _: 1
    })
  ]))
}