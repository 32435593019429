
// @ is an alias to /src
import { Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { Action, Getter, State } from 'vuex-class';
import { UserStateComponent } from '@/store/modules/user/types';
import MetroCheckbox from '@/components/MetroCheckbox';
import DialogYesNo from '@/components/DialogYesNo';
import Card from '@/components/UiComponents/Card';
import moment from 'moment';
import uk from 'moment';
moment.locale('uk');

@Options({
  name: 'loyalty-settings',
  components: {
    Card,
    DialogYesNo,
    'metro-checkbox': MetroCheckbox,
  },
})
export default class Settings extends Vue {
  @State('user')
  user!: UserStateComponent;
  @Getter('companiesCells', { namespace: 'user' })
  companiesCells!: any;
  @Getter('errorCode', { namespace: 'user' })
  errorCode!: string;
  @Getter('isLegalProfile', { namespace: 'user' })
  isLegalProfile!: boolean;
  @Action('blockCards', { namespace: 'user' })
  blockCards: any;
  @Action('showErrorModal', { namespace: 'modal' })
  showErrorModal: any;
  @Action('showSuccessModal', { namespace: 'modal' })
  showSuccessModal: any;
  @Action('showBonusesModal', { namespace: 'modal' })
  showBonusesModal: any;
  @Action('showCardsModal', { namespace: 'modal' })
  showCardsModal: any;
  @Action('getProfile', { namespace: 'user' })
  getProfile: any;
  @Action('getList', { namespace: 'discount' })
  getList: any;
  @Action('changeType', { namespace: 'discount' })
  changeType: any;

  companiesCellsLocal = [];

  mounted() {
    this.companiesCellsLocal = this.companiesCells;
  }

  get currentStatus(): any {
    return this.user.info.status;
  }

  get selected(): string {
    let hasSelectedCards = false;
    let hasSelectedBlocked = false;

    this.companiesCellsLocal.forEach((company: any) => {
      if (!company.cells) {
        return;
      }

      company.cells.forEach((row: any) => {
        row.forEach((item: any) => {
          if (!item.selected) {
            return;
          }

          if (item.client.clientType !== 'cardholder') {
            return;
          }

          if (item.client.cardIsBlocked) {
            hasSelectedBlocked = true;
          } else {
            hasSelectedCards = true;
          }
        });
      });
    });

    if (hasSelectedCards && hasSelectedBlocked) {
      return 'mixed';
    }

    if (hasSelectedCards) {
      return 'cards';
    }

    if (hasSelectedBlocked) {
      return 'blocked';
    }

    return '';
  }

  @Watch('companiesCells', { deep: true })
  async companiesCellsChange(val: boolean) {
    this.companiesCellsLocal = this.companiesCells;
  }

  onSelectCompanyChange(company: any) {
    if (!company.cells) {
      return;
    }

    company.cells.forEach((row: any) => {
      row.forEach((item: any) => {
        if (item.client.clientType !== 'cardholder') {
          return;
        }

        // this.$set(item, "selected", company.selected);     //vue2
        item['selected'] = company.selected; //vue3
      });
    });
  }

  onSelectClientChange(company: any, client: any) {
    if (!company.cells) {
      return;
    }

    // this.$set(company, "selected", true); //vue2
    company['selected'] = true; //vue3

    company.cells.forEach((row: any) => {
      row.forEach((item: any) => {
        if (item.client.clientType !== 'cardholder') {
          return;
        }

        if (!item.selected) {
          company.selected = false;
          // this.$set(company, "selected", false); //vue2
          company['selected'] = false; //vue3

          return false;
        }
      });
    });
  }

  getSelectedCardsIds(block: boolean): Array<number> {
    const selectedIds: Array<number> = [];

    this.companiesCellsLocal.forEach((company: any) => {
      if (!company.cells) {
        return;
      }

      company.cells.forEach((row: any) => {
        row.forEach((item: any) => {
          if (!item.selected) {
            return;
          }
          if (item.client.cardIsBlocked === block) {
            return;
          }
          if (item.client.clientType !== 'cardholder') {
            return;
          }

          selectedIds.push(item.client.id);
        });
      });
    });

    return selectedIds;
  }

  // async blockUnblockSingleCard(id: number, block: boolean) {
  //   await this.blockCards({ ids: [id], block });
  //   if (this.errorCode || this.user.hasError) {
  //     const title = block
  //       ? this.$t("settings_error_block_card")
  //       : this.$t("settings_error_unblock_card");
  //     this.showErrorModal({ title, message: "" });
  //     return;
  //   }
  //
  //   const message = block
  //     ? this.$t("settings_text_card_blocked")
  //     : this.$t("settings_text_card_unblocked");
  //   this.showSuccessModal({
  //     title: this.$t("settings_modal_title_ready"),
  //     message,
  //   });
  //   await this.getProfile();
  // }
  //
  // async blockUnblockSomeCards(block: boolean) {
  //   const ids = this.getSelectedCardsIds(block);
  //
  //   await this.blockCards({ ids, block });
  //   if (this.errorCode || this.user.hasError) {
  //     const title = block
  //       ? this.$t("settings_error_block_cards")
  //       : this.$t("settings_error_unblock_cards");
  //     this.showErrorModal({ title, message: "" });
  //     return;
  //   }
  //
  //   const message = block
  //     ? this.$t("settings_text_cards_blocked")
  //     : this.$t("settings_text_cards_unblocked");
  //   this.showSuccessModal({
  //     title: this.$t("settings_modal_title_ready"),
  //     message,
  //   });
  //   await this.getProfile();
  // }

  // NEW CODE
  get cardholders() {
    return this.user.info.cardholders;
  }
  get period(): any {
    if (this.user) {
      const user = this.user.info;
      const period_start = user.current_period_start;
      const period_end = user.current_period_end;

      const period = {
        start: moment(period_start).format('dddd, DD MMMM YYYY'),
        end: moment(period_end).format('dddd, DD MMMM YYYY'),
      };
      return period;
    }
    return null;
  }
  get discount(): any {
    const type = this.user.info.discountType.id;
    const percent = this.$tc('bonuses_type.percent', 0);
    const bonus = this.$tc('bonuses_type.bonus', 0);
    return type === 1 ? bonus : percent;
  }
  get discountName(): any {
    const type = this.user.info.discountType.id;
    const percent = this.$tc('bonuses_type.percent', 1);
    const bonus = this.$tc('bonuses_type.bonus', 2);
    return type === 1 ? bonus : percent;
  }
  get nextDiscountName(): any {
    const type = this.user.info.discountType.id;
    const percent = this.$tc('bonuses_type.percent', 3);
    const bonus = this.$tc('bonuses_type.bonus', 1);
    return type === 1 ? percent : bonus;
  }
  get discountNameKey(): any {
    const type = this.user.info.discountType.key;
    return type;
  }
  get nextDiscountNameKey(): any {
    const next = this.user.info.nextPeriodDiscountType;
    const type = next ? next.key : null;
    return type;
  }
  get popperHelp(): any {
    return {
      content: this.$t('loyalty.settings.change_help_tooltip'),
      theme: 'dropdown',
      offset: [0, 16],
      placement: 'right',
    };
  }
  // CHANGE TYPE DIALOG PAYLOAD
  get changeTypePayload(): any {
    const currentType = this.user.info.discountType.id;
    const changeType = currentType === 1 ? 2 : 1;

    return { discountTypeId: changeType };
  }

  // CHANGE DISCOUNT TYPE METHOD, CALLS STORE ACTION
  changeDiscountType() {
    this.changeType(this.changeTypePayload).then(() => {
      this.getProfile();
      this.showSuccessModal({
        title: this.$t('settings_modal_title_ready'),
        message: this.$t('settings_modal_text_ready_discount', {
          type: this.nextDiscountName,
        }),
      });
    });
  }
}
