
// @ is an alias to /src
import { Vue, Options } from 'vue-class-component';
import { Getter, State } from 'vuex-class';
import { UserStateComponent } from '@/store/modules/user/types';
// import Component from "vue-class-component";
import ExpansionPanel from '@/components/UiComponents/ExpansionPanel';
import Alert from '@/components/UiComponents/Alerts/Alert';

@Options({
  name: 'company-data',
  components: {
    ExpansionPanel,
    Alert,
  },
})
export default class CompanyData extends Vue {
  @State('user')
  user!: UserStateComponent;

  @Getter('isLegalProfile', { namespace: 'user' })
  isLegalProfile!: string;

  get is_company() {
    return this.user.info.company;
  }
  get name() {
    if (!this.is_company) return this.$t('no_data');
    const company_name = this.user.info.company.companyName;

    return company_name ? company_name : this.$t('no_data');
  }
  get address() {
    if (!this.is_company) return this.$t('no_data');
    const area = this.user.info.company.town;
    const district = this.user.info.company.street;
    const town = this.user.info.company.town;
    const street = this.user.info.company.street;
    const house = this.user.info.company.house;

    return `${area} ${district} ${town} ${street} ${house}`;
  }
  get email() {
    if (!this.is_company) return this.$t('no_data');
    const email = this.user.info.company.email;

    return email ? email : this.$t('no_data');
  }
  get is_cardholders() {
    return this.user.info.cardholders.length;
  }
}
