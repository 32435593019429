
import { Vue, Options } from 'vue-class-component';

@Options({})
export default class NotFoundPage extends Vue {
  onContinueClick() {
    const token = localStorage.getItem('access_token');
    if (token) {
      this.$router.push({ name: 'ProfilePage' });

      return;
    }

    this.$router.push({ name: 'LandingPage' });
  }
}
