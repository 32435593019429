
import { Vue, Options } from 'vue-class-component';

@Options({})
export default class LandingPage extends Vue {
  activeFAQ: number = 0;
  menuIsOpen: boolean = false;
  rulesPageLink: string = process.env.VUE_APP_RULES_PAGE_LINK || '#';

  scrollMeTo(refName: string) {
    this.menuIsOpen = false;
    var element = this.$refs[refName];
    var top = (element as HTMLElement).offsetTop;

    window.scrollTo(0, top);
  }
}
