
// @ is an alias to /src
import { Prop, Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { ModalStateComponent } from '@/store/modules/modal/types';
import { DiscountStateComponent } from '@/store/modules/discount/types';
import { UserStateComponent } from '@/store/modules/user/types';

import vSelect from 'vue-select';

@Options({
  components: {
    vSelect,
  },
})
export default class DiscountTypeModal extends Vue {
  @State('modal')
  modal!: ModalStateComponent;
  @State('user')
  user!: UserStateComponent;
  @State('discount')
  discount!: DiscountStateComponent;

  @Action('showBonusesModal', { namespace: 'modal' })
  showBonusesModal: any;
  @Action('hideBonusesModal', { namespace: 'modal' })
  hideBonusesModal: any;
  @Action('showSuccessModal', { namespace: 'modal' })
  showSuccessModal: any;
  @Action('getList', { namespace: 'discount' })
  getList: any;
  @Action('changeType', { namespace: 'discount' })
  changeType: any;

  @Getter('errorCode', { namespace: 'discount' })
  errorCode!: string;

  selectedBox = '';
  radio = '';
  typeId = -1;

  @Watch('modal.bonusesModal.show', { deep: true })
  async changeModalShow(val: any) {
    if (val) {
      await this.getList();
      // this.validErrorMessage = '';
      this.radio = this.user.info.discountType.key;
    }
  }

  filterRadioItems(item: any) {
    return item.key === this.radio;
  }

  @Watch('radio', { deep: true })
  async changeRadio(val: any) {
    // if(val){
    const result = this.discount.list.find(this.filterRadioItems);
    this.typeId = result ? result.id : -1;
    this.validErrorMessage = '';
    // }
  }

  validErrorMessage = '';

  errorHandler(errorCode: string) {
    switch (errorCode) {
      case 'VALID':
        this.validErrorMessage = this.$t('voucher_modal_validation_error');
        break;

      default:
        this.validErrorMessage = this.$t('voucher_modal_server_error');
        break;
    }
  }

  validForChangeType() {
    return this.radio;
  }

  async change() {
    if (this.validForChangeType()) {
      await this.changeType({ discountTypeId: this.typeId });
      if (this.errorCode || this.discount.hasError) {
        this.errorHandler(this.errorCode);
      } else {
        this.showSuccessModal({
          title: this.$t('settings_modal_title_ready'),
          message: this.$t('settings_modal_text_ready_discount'),
        });
        this.hideBonusesModal();
      }
    } else {
      this.errorHandler('VALID');
    }
  }
}
