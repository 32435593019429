import { ActionTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '@/store/types';
import Api from '@/api';

export const actions: ActionTree<UserState, RootState> = {
  getProfile({ commit }): any {
    commit('setPending');
    return Api.user.getProfile().then(
      (response) => {
        const payload = response && response.data;
        commit('getProfileDone', payload);
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
  confirmTerms({ commit }): any {
    commit('setPending');
    return Api.user.confirm().then(
      (response) => {
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
  setEmail({ commit }, { email }): any {
    commit('setPending');
    return Api.user.setEmail(email).then(
      (response) => {
        // const payload = response && response.data;
        // commit('getProfileDone', payload);
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
  verifyEmail({ commit }, { code }): any {
    commit('setPending');
    return Api.user.verifyEmail(code).then(
      (response) => {
        // const payload = response && response.data;
        // commit('getProfileDone', payload);
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
  changeLimit({ commit }, { limit, userId }): any {
    commit('setPending');
    return Api.user.changeLimit(userId, limit).then(
      (response) => {
        // const payload = response && response.data;
        // commit('getProfileDone', payload);
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
  blockCards({ commit }, { ids, block }): any {
    commit('setPending');
    return Api.user.blockCards(ids, block).then(
      (response) => {
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
};
