import { ActionTree } from 'vuex';
import { DiscountState } from './types';
import { RootState } from '@/store/types';
import Api from '@/api';

export const actions: ActionTree<DiscountState, RootState> = {
  getList({ commit }): any {
    commit('setPending');
    return Api.discount.getList().then(
      (response) => {
        const payload = response && response.data;
        commit('unsetPending');
        commit('unsetError');
        commit('setList', payload);
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
  changeType({ commit, dispatch }, { discountTypeId }): any {
    commit('setPending');
    return Api.discount.changeType(discountTypeId).then(
      (response) => {
        commit('unsetPending');
        commit('unsetError');
        return response;
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
};
