
// @ is an alias to /src
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { ModalStateComponent } from '@/store/modules/modal/types';

@Options({})
export default class SuccessModal extends Vue {
  @State('modal')
  modal!: ModalStateComponent;
  @Action('hideSuccessModal', { namespace: 'modal' })
  hideSuccessModal: any;
}
