
// @ is an alias to /src
import { Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { Action, Getter, State } from 'vuex-class';
import { Voucher, VoucherStateComponent } from '@/store/modules/voucher/types';
import MetroCheckbox from '@/components/MetroCheckbox';
import { UserStateComponent } from '@/store/modules/user/types';
import moment from 'moment';
import { ModalStateComponent } from '@/store/modules/modal/types';

@Options({
  name: 'loyalty-vouchers',
  components: {
    'metro-checkbox': MetroCheckbox,
  },
})
export default class Vouchers extends Vue {
  @State('modal')
  modal!: ModalStateComponent;

  @State('user')
  user!: UserStateComponent;

  @State('voucher')
  voucher!: VoucherStateComponent;

  @Getter('errorCode', { namespace: 'voucher' })
  voucherErrorCode!: string;

  @Getter('isLegalProfile', { namespace: 'user' })
  isLegalProfile!: string;

  @Action('getList', { namespace: 'voucher' })
  getVoucherList: any;

  @Action('getFaceValueList', { namespace: 'voucher' })
  getFaceValueList: any;

  @Action('generateVoucher', { namespace: 'voucher' })
  generateVoucher: any;

  @Action('deactivateVoucher', { namespace: 'voucher' })
  deactivateVoucher: any;

  @Action('showErrorModal', { namespace: 'modal' })
  showErrorModal: any;

  @Action('showVoucherModal', { namespace: 'modal' })
  showVoucherModal: any;

  @Action('hideVoucherModal', { namespace: 'modal' })
  hideVoucherModal: any;

  @Action('getProfile', { namespace: 'user' })
  getProfile: any;

  @Watch('voucher.list', { deep: true })
  async selectedVouchersIndexesClear() {
    this.selectedVouchersIndexes.splice(0, this.selectedVouchersIndexes.length);
  }

  @Watch('modal.voucherModal.show', { deep: true })
  async closeVoucherModal(val: boolean) {
    if (!val) {
      await this.getVoucherList({
        params: {
          page: this.currentPage,
          order_field: this.sortField,
          order_method: this.sortOrder,
        },
      });
    }
  }

  created() {
    this.getVoucherList({ params: { page: 1 } });
    this.getFaceValueList({});
  }

  selectedVouchersIndexes = [];
  sortField = '';
  sortOrder = '';
  currentPage = 1;

  get selectedVouchers(): Array<Voucher> {
    return this.voucher.list.filter((voucher) =>
      this.checkVoucherSelected(voucher.id)
    );
  }

  formatDate(date: string) {
    return date ? moment(date).format('DD.MM.YYYY') : '-';
  }

  async changeSort(sortName: string) {
    if (this.sortField !== sortName) {
      this.sortField = sortName;
      this.sortOrder = 'asc';
    } else {
      this.sortField = sortName;
      switch (this.sortOrder) {
        case 'asc':
          this.sortOrder = 'desc';
          break;
        default:
          this.sortOrder = 'asc';
          break;
      }
    }

    await this.getVoucherList({
      params: {
        page: this.currentPage,
        order_field: this.sortField,
        order_method: this.sortOrder,
      },
    });
  }

  async changePageCallback(page: number) {
    this.currentPage = page;
    await this.getVoucherList({
      params: {
        page: this.currentPage,
        order_field: this.sortField,
        order_method: this.sortOrder,
      },
    });
  }

  checkVoucherSelected(voucherId: number): boolean {
    //@ts-ignore
    return this.selectedVouchersIndexes.includes(voucherId);
  }

  checkVoucherSelectedAll(): boolean {
    for (const key in this.voucher.list) {
      const voucher = this.voucher.list[key];
      //@ts-ignore
      if (!this.selectedVouchersIndexes.includes(voucher.id)) {
        return false;
      }
    }

    return true;
  }

  onVoucherCheck(voucherId: number) {
    //@ts-ignore
    const index = this.selectedVouchersIndexes.indexOf(voucherId);
    if (index === -1) {
      //@ts-ignore
      this.selectedVouchersIndexes.push(voucherId);
      return;
    }

    this.selectedVouchersIndexes.splice(index, 1);
  }

  onVoucherCheckAll(selected: boolean) {
    this.voucher.list.forEach((voucher) => {
      //@ts-ignore
      const includes = this.selectedVouchersIndexes.includes(voucher.id);
      if (selected === includes) {
        return true;
      }

      this.onVoucherCheck(voucher.id);
    });
  }

  printVouchers(vouchers: Array<Voucher>) {
    if (!vouchers.length) {
      return;
    }

    let html =
      '<style type="text/css">' +
      ' p { letter-spacing: 0.8px; font-size: 20px; font-family: Courier }' +
      ' .print-voucher-container { margin-bottom: 85px; page-break-inside: avoid }' +
      '</style>';

    vouchers.forEach((voucherItem: Voucher) => {
      const parts = voucherItem.epc.split(':');
      let barcode = parts.pop();
      if (!barcode) {
        return;
      }
      barcode = barcode.replace(/\./g, '');

      const url =
        'https://barcode-generator.cf-vvv-prod-rd.cf.metro.cloud/?bcid=databarexpandedstacked&text=(255)' +
        barcode +
        '&scaleX=2&scaleY=1&segments=8&alttext=(255)' +
        barcode +
        '&backgroundcolor=FFFFFF&paddingheight=5&paddingwidth=5';

      const voucherEndDate =
        voucherItem.created_at >= this.user.info.current_period_start
          ? this.user.info.current_period_end
          : this.user.info.current_period_start;

      const clientBarcode = this.isLegalProfile
        ? voucherItem.client.barcode
        : this.user.info.barcode;
      html +=
        '<div class="print-voucher-container">' +
        `<img src="${url}" alt="voucher barcode" />` +
        `<br><br><br>` +
        `<p>${this.$t('voucher_table_card')}: ${clientBarcode}</p>` +
        `<p>${this.$t('voucher_table_campaign')}: ${
          voucherItem.face_value && voucherItem.face_value.amount
            ? voucherItem.face_value.amount
            : '-'
        }</p>` +
        `<p>${this.$t('voucher_table_enddate')}: ${this.formatDate(
          voucherEndDate
        )}</p>` +
        '</div>';
    });

    const iframe = document.createElement('iframe');
    iframe.setAttribute(
      'style',
      'position:absolute;width:0;height:0;top:-100%;left:-100%'
    );
    iframe.classList.add('voucher-print-iframe');

    const el = document.body.appendChild(iframe);
    if (!el) {
      return;
    }
    const printWindow = el.contentWindow;
    if (printWindow === null) {
      return;
    }
    if (!el.contentDocument && !el.contentWindow) {
      return;
    }
    //@ts-ignore
    const printDocument = el.contentDocument || el.contentWindow.document;
    printDocument.open();
    printDocument.write(html);
    printDocument.close();

    iframe.onload = function () {
      printWindow.print();
    };
  }

  async deactivateVoucherClick(voucher: Voucher) {
    await this.deactivateVoucher(voucher.id);

    if (this.voucherErrorCode) {
      this.showErrorModal({
        title: this.$t('voucher_deactivate_error'),
        message: '',
      });
      return;
    }

    this.getVoucherList({ params: { page: 1 } });
    this.getProfile();
  }
}
