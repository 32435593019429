import { MutationTree } from 'vuex';
import { AuthState, AuthResponseData } from './types';

export const mutations: MutationTree<AuthState> = {
  // authDone(state, payload: AuthResponseData) {
  //     state.error = false;
  // },
  // authError(state) {
  //     state.error = true;
  // },

  sendSMSDone(state, payload) {
    // state.error = false;
    state.timerSeconds = payload.timer;
    state.attempts = payload.attempts;
  },

  sendSMSError(state, payload) {
    // state.error = true;
    state.timerSeconds = null;
    state.attempts = null;
    // state.errorMessage = payload.message;
  },

  // authWithSMSCodeDone(state, payload) {
  //     // state.error = false;
  // },
  // authWithSMSCodeError(state, payload) {
  //     // state.error = true;
  // }
};
