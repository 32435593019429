
import { Component, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { NotificationStateComponent } from '@/store/modules/notifications/types';
import { UserStateComponent } from '@/store/modules/user/types';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {},
})
export default class NotificationsPage extends Vue {
  @State('user')
  user!: UserStateComponent;

  @Watch('user.info.unread_notifications', { deep: true })
  unreadNotificationsChange() {
    this.showList();
  }

  @Action('getProfile', { namespace: 'user' })
  getProfile: any;

  @State('notifications')
  notifications!: NotificationStateComponent;

  @Action('getList', { namespace: 'notifications' })
  getNotificationsList: any;

  @Action('getUnReadList', { namespace: 'notifications' })
  getNotificationsUnReadList: any;

  @Action('setPageRead', { namespace: 'notifications' })
  setNotificationsPageRead: any;

  mounted() {
    this.getProfile();
  }

  showList() {
    if (!this.user.info.unread_notifications) {
      this.getNotificationsList({});
      return;
    }

    this.getNotificationsUnReadList().then(() => {
      this.$nextTick(() => {
        this.setNotificationsPageRead(1);
      });
    });
  }

  async nextPage() {
    await this.getProfile();
  }
}
