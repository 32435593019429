import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "metro-checkbox" }
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hidden)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
          id: _ctx.inputId,
          name: _ctx.name,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
          type: "checkbox",
          class: "checkbox-checkbox"
        }, null, 8, _hoisted_2)), [
          [_vModelCheckbox, _ctx.checked]
        ])
      : _createCommentVNode("", true),
    (!_ctx.hidden)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: _ctx.inputId,
          class: "checkbox-label"
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 8, _hoisted_3))
      : (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ]))
  ]))
}