import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { VoucherState } from './types';
import { RootState } from '../../types';
import combainer from '@/store/mixins/vuexCombainer';
import errorHandler from '@/store/mixins/errorHandler';
import pendingHandler from '@/store/mixins/pendingHandler';

export const state: VoucherState = {
  list: [],
  pagination: {
    current_page: null,
    last_page: null,
    total: null,
    per_page: null,
    from: null,
    to: null,
  },
  faceValueList: [],
};

const namespaced: boolean = true;

const voucher = combainer(
  {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  },
  errorHandler(),
  pendingHandler()
);

export default voucher;
