
// @ is an alias to /src
import { Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import LoginModalWrapper from '@/components/LoginModalWrapper';
import LoginErrorModal from '@/components/LoginErrorModal';
import { Action, Getter, State } from 'vuex-class';
import { AuthData, LoginData } from '@/api/types';
import { AuthStateComponent } from '@/store/modules/auth/types';
import { UserStateComponent } from '@/store/modules/user/types';
import { Validator } from '@/utils';

type VInput = Vue & { focus: () => {} };

interface ServerErrorsMessages {
  errorHeadTitle: string;
  errorWarningText: string;
  errorWarningMessage: string;
  contactPhone: string;
  contactEmail: string;
}

@Options({
  components: {
    LoginModalWrapper,
    LoginErrorModal,
  },
})
export default class LoginPage extends Vue {
  private isValidError: boolean = false;
  private carPart1: string = '804010';
  private carPart2: string = '';
  private carPart3: string = '';
  private carPart4: string = '';
  private code: string = '';
  private firestCheck: boolean = false;
  private step: number = 1;
  private isSMSError: boolean = false;
  private SMSCode: string[] = ['', '', '', ''];
  private timerSeconds: number = 30;
  private timer: any = null;
  private isServerError: boolean = false;
  private serverError: ServerErrorsMessages = {
    errorHeadTitle: '',
    errorWarningText: '',
    errorWarningMessage: '',
    contactPhone: '',
    contactEmail: '',
  };

  @State('auth')
  auth!: AuthStateComponent;
  @State('user')
  user!: UserStateComponent;
  @Getter('errorCode', { namespace: 'auth' })
  errorCode!: string;
  @Getter('errorCode', { namespace: 'user' })
  userErrorCode!: string;
  @Action('getProfile', { namespace: 'user' })
  getProfile: any;
  @Action('sendSMS', { namespace: 'auth' })
  sendSMS: any;
  @Action('authWithSMSCode', { namespace: 'auth' })
  authWithSMSCode: any;

  agreeCheckbox: boolean = false;
  agreeCheckboxError: boolean = false;

  @Watch('agreeCheckbox', { deep: true })
  handleAgreeCheckboxChange(check: boolean) {
    if (check) {
      this.agreeCheckboxError = false;
    }
  }

  get login(): string {
    return this.carPart1 + this.carPart2 + this.carPart3 + this.carPart4;
  }

  get isDisabled() {
    return (
      !(
        Validator.phoneNumber(this.login, true) ||
        Validator.clientCard(this.login)
      ) && this.firestCheck
    );
  }

  get fullSMSCode() {
    return this.SMSCode.join('');
  }

  get loginForServer() {
    return this.login.indexOf('+') !== -1
      ? this.login.replace('+', '')
      : this.login;
  }

  // mounted() {
  // console.log('mount \n',this.$t,'\n', this.$i18n)
  // }

  handleInputDigit(number: number) {
    if (!this.validateSmsCode(this.fullSMSCode)) {
      this.isValidError = true;
      this.isSMSError = true;
      return false;
    }
    if (this.SMSCode[number - 1] && number < 4) {
      (this.$refs[`digit_${number + 1}`] as HTMLInputElement)?.focus();
    }
    if (this.isSMSError === true) {
      this.isSMSError = false;
    }
  }

  handleDeleteDigit(number: number) {
    if (!this.SMSCode[number - 1] && number > 1) {
      (this.$refs[`digit_${number - 1}`] as HTMLInputElement)?.focus();
    }
    if (this.isSMSError === true) {
      this.isSMSError = false;
    }
  }

  handleSecondDigit(event: KeyboardEvent, number: number) {
    if (this.SMSCode[number - 1] && !isNaN(+event.key) && number < 4) {
      this.SMSCode[number] = event.key;
      (this.$refs[`digit_${number + 1}`] as HTMLInputElement)?.focus();
    }
    if (this.isSMSError === true) {
      this.isSMSError = false;
    }
  }

  checkSeconds() {
    this.timerSeconds -= 1;
    if (this.timerSeconds < 1) {
      this.stopTimerToSendSMS();
      this.sendSMSCode();
    }
  }

  startTimerToSendSMS(secondsToEnd: number) {
    if (this.timer) {
      this.stopTimerToSendSMS();
    }
    this.timerSeconds = secondsToEnd;
    this.timer = setInterval(this.checkSeconds, 1000);
  }

  setTimerSeconds(secondsToEnd: number) {
    this.timerSeconds = secondsToEnd;
  }

  stopTimerToSendSMS() {
    this.SMSCode = ['', '', '', ''];
    clearInterval(this.timer);
  }

  triggerUnsuccessfulLogin(errorCode?: string) {
    this.isServerError = true;
    let errorScreenText = {
      errorHeadTitle: '',
      errorWarningText: '',
      errorWarningAppeal: '',
      errorWarningMessagesListParagraph: '',
      errorWarningMessagesList: [] as any,
      errorWarningMessagesAfterListParagraph: '',
      errorWarningMessage: '',
      contactPhone: this.$t('non-legal_login_text_contactphone'),
      schedule: '',
      contactEmail: this.$t('non-legal_login_text_contactmail'),
    };
    switch (errorCode) {
      case 'ERR6000':
        errorScreenText.errorHeadTitle = this.$t('general_auth_error');
        errorScreenText.errorWarningMessage = this.$t(
          'non-legal_login_text_store'
        );
      case 'ERR2007':
        errorScreenText.errorWarningMessage = this.$t(
          'non-legal_login_text_store'
        );
      case 'ERR4203':
      case 'ERR6002':
        errorScreenText.errorHeadTitle = this.$t(
          'non-legal_login_title_unsuccessful'
        );
        errorScreenText.errorWarningText = this.$t(
          'non-legal_login_text_unsuccessful'
        );
        errorScreenText.errorWarningMessage = this.$t(
          'non-legal_login_text_store'
        );
        break;
      case 'ERR6003':
        errorScreenText.errorHeadTitle = this.$t(
          'non-legal_login_title_notexist'
        );
        errorScreenText.errorWarningText = this.$t(
          'non-legal_login_text_notexist'
        );
        errorScreenText.errorWarningMessage = this.$t(
          'non-legal_login_text_store'
        );
        break;
      case 'ERR6004':
        errorScreenText.errorHeadTitle = this.$t(
          'non-legal_login_title_inaccessible'
        );
        errorScreenText.errorWarningAppeal = this.$t('non-legal_login_appeal');
        errorScreenText.errorWarningMessagesListParagraph = this.$t(
          'non-legal_login_paragpraph'
        );
        errorScreenText.errorWarningMessagesList = [
          this.$t('non-legal_login_list_item_1'),
          this.$t('non-legal_login_list_item_2'),
          this.$t('non-legal_login_list_item_3'),
          this.$t('non-legal_login_list_item_4'),
          this.$t('non-legal_login_list_item_5'),
          this.$t('non-legal_login_list_item_6'),
        ];
        errorScreenText.errorWarningMessagesAfterListParagraph = this.$t(
          'non-legal_login_paragpraph_after'
        );
        errorScreenText.schedule = this.$t('non-legal_login_text_shedule');
        break;
      case 'ERR6006':
        errorScreenText.errorHeadTitle = this.$t(
          'non-legal_login_title_inaccessible'
        );
        errorScreenText.errorWarningText = this.$t(
          'non-legal_login_text_not_individual'
        );
        errorScreenText.errorWarningMessage = this.$t(
          'non-legal_login_text_store'
        );
        break;
      case 'ERR6007':
        errorScreenText.errorHeadTitle = this.$t(
          'non-legal_login_title_notexist'
        );
        errorScreenText.errorWarningMessage = this.$t(
          'legal_login_text_message'
        );
        break;
      default:
        break;
    }

    this.serverError = { ...errorScreenText };
  }

  handleLoginInputChange(value: string, maxCount: number, event: any) {
    if (this.isValidError) {
      this.isValidError = false;
    }

    if (!value || value.length < maxCount) {
      return;
    }

    if (!event || !event.target.nextElementSibling) {
      return;
    }

    event.target.nextElementSibling.focus();
  }

  handleLoginPaste(event: any) {
    if (!event || !event.clipboardData) {
      return;
    }

    const text = event.clipboardData.getData('text');

    if (text.length !== 22) {
      return;
    }

    if (text.substr(0, 6) !== '804010') {
      return;
    }

    this.carPart2 = text.substr(6, 6);
    this.carPart3 = text.substr(12, 5);
    this.carPart4 = text.substr(17, 5);
  }

  validateLoginData() {
    let isValid =
      Validator.phoneNumber(this.login, true) ||
      Validator.clientCard(this.login);

    if (!isValid) {
      this.firestCheck = true;
      this.isValidError = true;
    }

    return isValid;
  }

  validateAgreeCheckbox() {
    if (!this.agreeCheckbox) {
      this.agreeCheckboxError = true;
    }
    return this.agreeCheckbox;
  }

  async sendSMSCode() {
    if (this.validateLoginData() && this.validateAgreeCheckbox()) {
      if (!this.auth.isPending) {
        let loginData: LoginData = {
          login: this.loginForServer,
        };
        if (this.$route.params.accountType === 'business' && this.code) {
          loginData.code = this.code;
        }
        await this.sendSMS({ loginData });
        if (this.auth.hasError) {
          this.triggerUnsuccessfulLogin(this.errorCode);
        } else {
          this.startTimerToSendSMS(this.auth.timerSeconds as number);
          if (this.step === 1) {
            this.step = 2;
            await this.$nextTick();
            (this.$refs[`digit_1`] as VInput).focus();
          }
        }
      }
    }
  }

  async sendCode() {
    if (this.fullSMSCode.length === 4 && !this.auth.isPending) {
      let authData: AuthData = {
        login: this.loginForServer,
        verification_code: this.fullSMSCode,
      };
      if (this.$route.params.accountType === 'business' && this.code) {
        authData.code = this.code;
      }
      await this.authWithSMSCode({ authData });
      // .then(() => {
      if (this.auth.hasError) {
        if (this.errorCode === 'ERR6001') {
          this.isSMSError = true;
          this.sendSMSCode();
        } else {
          this.triggerUnsuccessfulLogin(this.errorCode);
        }
      } else {
        this.stopTimerToSendSMS();
        await this.getProfile();
        if (this.user.info.isAgree) {
          //this.user.info.isAgree
          this.$router.push({ path: '/account/profile' });
        } else {
          this.$router.push({ path: '/agree/loyalty' });
        }
      }
      // })
    }
  }

  errorHandleOK() {
    this.step = 1;
    this.isServerError = false;
    this.carPart2 = '';
    this.carPart3 = '';
    this.carPart4 = '';
    this.SMSCode = ['', '', '', ''];
    this.$router.push({ path: '/login/person' });
  }

  validateSmsCode(code: any) {
    return Validator.smsCode(code);
  }
}
