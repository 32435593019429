import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { InvoiceState } from './types';
import { RootState } from '../../types';
import combainer from '@/store/mixins/vuexCombainer';
import errorHandler from '@/store/mixins/errorHandler';
import pendingHandler from '@/store/mixins/pendingHandler';

export const state: InvoiceState = {
  list: [],
  pagination: {
    current_page: null,
    last_page: 0,
    total: null,
    per_page: null,
    from: null,
    to: null,
  },
};

const namespaced = true;

const invoice = combainer(
  {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  },
  errorHandler(),
  pendingHandler()
);

export default invoice;
