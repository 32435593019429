
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

@Options({})
export default class MetroCheckbox extends Vue {
  @Prop({ type: Boolean, required: true }) readonly 'value': boolean;
  @Prop({ type: Boolean, default: false }) readonly 'hidden': boolean;
  @Prop({ type: String, default: '' }) readonly 'name': string;
  private _uid: any;

  get inputId(): string {
    return 'metro-checkbox-id-' + this._uid;
  }

  get checked(): boolean {
    return this.value;
  }
  set checked(newValue: boolean) {
    this.$emit('input', newValue);
    this.$emit('change', newValue);
  }

  onClick() {
    setTimeout(() => {
      this.$emit('click');
    }, 10);
  }
}
