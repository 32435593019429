import { AxiosInstance } from 'axios';

export default ($axios: AxiosInstance) => ({
  getProfile() {
    return $axios.get('/clients/info');
  },
  confirm() {
    return $axios.post('/clients/confirm');
  },
  setEmail(email: string) {
    return $axios.post('/clients/email/set', { email });
  },
  verifyEmail(code: string) {
    return $axios.post('/clients/email/verify', { code });
  },
  changeLimit(client: number, limit: number) {
    return $axios.put(`/clients/${client}`, { limit });
  },
  blockCards(ids: Array<number>, block: boolean) {
    return $axios.post(`/clients/block`, { ids, block });
  },
});
