import { Module } from 'vuex';

export default <ModuleState, RootState>(
  moduleState: Module<ModuleState, RootState>,
  ...mixinStates: Module<any, any>[]
) => {
  moduleState.actions = {
    ...Object.assign({}, ...mixinStates.map((mS) => mS.actions)),
    ...moduleState.actions,
  };

  moduleState.mutations = {
    ...Object.assign({}, ...mixinStates.map((mS) => mS.mutations)),
    ...moduleState.mutations,
  };

  moduleState.state = {
    ...Object.assign({}, ...mixinStates.map((mS) => mS.state)),
    ...moduleState.state,
  };

  moduleState.getters = {
    ...Object.assign({}, ...mixinStates.map((mS) => mS.getters)),
    ...moduleState.getters,
  };

  return moduleState;
};
