import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "success-modal query-modal feedback" }
const _hoisted_2 = { class: "modal-body" }
const _hoisted_3 = {
  key: 0,
  class: "modal-heading"
}
const _hoisted_4 = { class: "modal-message modal-message-error" }
const _hoisted_5 = { class: "modal-btn-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "activator", {
      on: { click: _ctx.dialogOpen }
    }, () => [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dialogOpen && _ctx.dialogOpen(...args))),
        class: "primary-btn grey-btn",
        "data-test": "dialog-activator"
      }, _toDisplayString(_ctx.activatorButtonLabel), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([{ open: _ctx.showDialog }, 'custom-modal-overlay']),
      onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dialogClose && _ctx.dialogClose(...args)), ["self"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "title"),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.message), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onButtonYesClick && _ctx.onButtonYesClick(...args))),
              class: "primary-btn",
              "data-test": "dialog-confirm"
            }, _toDisplayString(_ctx.yesButtonLabel ? _ctx.yesButtonLabel : _ctx.$t('settings_modal_button_ok')), 1),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dialogClose && _ctx.dialogClose(...args))),
              class: "primary-btn grey-btn",
              "data-test": "dialog-close"
            }, _toDisplayString(_ctx.noButtonLabel
                  ? _ctx.noButtonLabel
                  : _ctx.$t('settings_modal_button_cancel')), 1)
          ])
        ])
      ])
    ], 2)
  ]))
}