import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-page" }

export function render(_ctx, _cache) {
  const _component_navigation = _resolveComponent("navigation")
  const _component_router_view = _resolveComponent("router-view")
  const _component_error_modal = _resolveComponent("error-modal")
  const _component_success_modal = _resolveComponent("success-modal")
  const _component_email_modal = _resolveComponent("email-modal")
  const _component_voucher_generate_modal = _resolveComponent("voucher-generate-modal")
  const _component_limit_change_modal = _resolveComponent("limit-change-modal")
  const _component_discount_type_modal = _resolveComponent("discount-type-modal")
  const _component_welcome_modal = _resolveComponent("welcome-modal")
  const _component_cards_modal = _resolveComponent("cards-modal")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_navigation, {
      username: _ctx.username,
      unread: _ctx.user.info.unread_notifications
    }, null, 8, ["username", "unread"]),
    _createVNode(_component_router_view),
    _createVNode(_component_error_modal),
    _createVNode(_component_success_modal),
    _createVNode(_component_email_modal),
    _createVNode(_component_voucher_generate_modal),
    _createVNode(_component_limit_change_modal),
    _createVNode(_component_discount_type_modal),
    _createVNode(_component_welcome_modal),
    _createVNode(_component_cards_modal)
  ]))
}