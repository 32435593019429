import { ActionTree } from 'vuex';
import { VoucherState } from './types';
import { RootState } from '@/store/types';
import Api from '@/api';

export const actions: ActionTree<VoucherState, RootState> = {
  getList({ commit }, { params }): any {
    commit('setPending');
    return Api.voucher.getList(params).then(
      (response) => {
        const payload = response && response.data;
        commit('unsetPending');
        commit('unsetError');
        commit('setList', payload);
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },

  getFaceValueList({ commit }, { params }): any {
    commit('setPending');
    return Api.voucher.getFaceValueList(params).then(
      (faceValueResponse) => {
        const payload = faceValueResponse && faceValueResponse.data;
        commit('unsetPending');
        commit('unsetError');
        commit('setFaceValueList', payload);
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },

  generateVoucher({ commit }, { data }): any {
    commit('setPending');
    return Api.voucher.generate(data).then(
      (response) => {
        // const payload  = response && response.data;
        commit('unsetPending');
        commit('unsetError');
        // commit('setList', payload);
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },

  deactivateVoucher({ commit }, voucherId): any {
    commit('setPending');
    return Api.voucher.deactivate(voucherId).then(
      (response) => {
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
};
