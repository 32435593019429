
// @ is an alias to /src
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'progress-bar',
})
export default class Progress extends Vue {
  // PROPERTIES
  @Prop({ type: Number, default: 0 }) 'width': number;
}
