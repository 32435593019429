import { MutationTree } from 'vuex';
import { ModalState } from './types';

export const mutations: MutationTree<ModalState> = {
  showErrorModal(state, payload) {
    state.errorModal = {
      show: true,
      title: payload.title,
      message: payload.message,
    };
  },
  hideErrorModal(state, payload) {
    state.errorModal = {
      ...state.errorModal,
      show: false,
    };
  },
  cleanErrorModalContent(state, payload) {
    state.errorModal = {
      ...state.errorModal,
      title: '',
      message: '',
    };
  },

  showSuccessModal(state, payload) {
    state.successModal = {
      show: true,
      title: payload.title,
      message: payload.message,
    };
  },
  hideSuccessModal(state, payload) {
    state.successModal = {
      ...state.successModal,
      show: false,
    };
  },
  cleanSuccessModalContent(state, payload) {
    state.successModal = {
      ...state.successModal,
      title: '',
      message: '',
    };
  },

  showEmailModal(state, payload) {
    state.emailModal = {
      show: true,
    };
  },
  hideEmailModal(state, payload) {
    state.emailModal = {
      show: false,
    };
  },

  showVoucherModal(state, payload) {
    state.voucherModal = {
      show: true,
    };
  },
  hideVoucherModal(state, payload) {
    state.voucherModal = {
      show: false,
    };
  },

  showLimitModal(state, payload) {
    state.limitModal = {
      show: true,
      user: payload.user,
    };
  },
  hideLimitModal(state, payload) {
    state.limitModal = {
      show: false,
      user: null,
    };
  },

  showBonusesModal(state, payload) {
    state.bonusesModal = {
      show: true,
    };
  },
  hideBonusesModal(state, payload) {
    state.bonusesModal = {
      show: false,
    };
  },

  showWelcomeModal(state, payload) {
    state.welcomeModal = {
      show: true,
    };
  },
  hideWelcomeModal(state, payload) {
    state.welcomeModal = {
      show: false,
    };
  },
  showCardsModal(state, payload): any {
    state.cardsModal = {
      show: true,
      block: payload,
    };
  },
  hideCardsModal(state, payload): any {
    state.cardsModal = {
      show: false,
    };
  },
};
