
// @ is an alias to /src
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import moment from 'moment';

@Options({})
export default class InvoiceItem extends Vue {
  @Prop({ default: {} }) readonly source!: any;
  @Prop({ default: false }) readonly isLegalProfile!: boolean;

  formatDate(date: Date, format: string = 'DD.MM.YYYY HH:mm:ss') {
    return moment(date).format(format);
  }
}
