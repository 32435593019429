import { AxiosInstance } from 'axios';
import { Params } from '@/utils';

export default ($axios: AxiosInstance) => ({
  getList(params: Params = {}) {
    return $axios.get('/notifications');
  },
  getUnReadList() {
    return $axios.get('/notifications/unread');
  },
  setPageRead() {
    return $axios.post(`/notifications/unread`);
  },
});
