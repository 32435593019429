import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "auth-error-modal" }
const _hoisted_2 = { class: "auth-error-modal-body" }
const _hoisted_3 = { class: "auth-error-heading" }
const _hoisted_4 = { class: "auth-error-warning" }
const _hoisted_5 = { class: "auth-error-message" }
const _hoisted_6 = { class: "auth-error-message" }
const _hoisted_7 = { class: "auth-error-message" }
const _hoisted_8 = {
  key: 0,
  class: "auth-error-list"
}
const _hoisted_9 = { class: "auth-error-message" }
const _hoisted_10 = {
  key: 1,
  class: "auth-error-phone-wrapper"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 2,
  class: "auth-error-email-wrapper"
}
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "auth-error-message" }
const _hoisted_15 = { class: "auth-error-btn-wrapper" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.errorHeadTitle), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.errorWarningText), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.errorWarningMessage), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.errorWarningAppeal), 1),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.errorWarningMessagesListParagraph), 1),
      (_ctx.errorWarningMessagesList.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorWarningMessagesList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "auth-error-message",
                key: index
              }, _toDisplayString(item), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.errorWarningMessagesAfterListParagraph), 1),
      (_ctx.phoneLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("a", {
              href: _ctx.phoneLink,
              class: "auth-error-phone"
            }, _toDisplayString(_ctx.contactPhone), 9, _hoisted_11)
          ]))
        : _createCommentVNode("", true),
      (_ctx.emailLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("a", {
              href: _ctx.emailLink,
              class: "auth-error-email"
            }, _toDisplayString(_ctx.contactEmail), 9, _hoisted_13)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.schedule), 1),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.handleOKTrigger && _ctx.handleOKTrigger(...args))),
          class: "error-modal-ok primary-btn"
        }, _toDisplayString(_ctx.$t('non-legal_login_button_ok')), 1)
      ])
    ])
  ]))
}