
// @ is an alias to /src
import { Vue, Options } from 'vue-class-component';
import { Action, Getter, State } from 'vuex-class';
import { UserStateComponent } from '@/store/modules/user/types';
import moment from 'moment';

import ProgressBar from '@/components/UiComponents/Progress';

@Options({
  name: 'loyalty-basic',
  components: {
    ProgressBar,
  },
})
export default class Basic extends Vue {
  @State('user')
  user!: UserStateComponent;
  // TODO: REPLACE THIS TO BACK END
  @Getter('activeStatuses', { namespace: 'status' })
  activeStatuses!: any[];
  // TODO: REPLACE THIS TO BACK END
  @Getter('percentOfCurrentStatus', { namespace: 'status' })
  percentOfCurrentStatus!: any[];
  @Action('getList', { namespace: 'status' })
  getStatusesList: any;

  rulesPageLink: string = process.env.VUE_APP_RULES_PAGE_LINK || '#';

  // LOCAL GETTERS
  get isBonus() {
    const user = this.user.info;
    const type = user.discountType.key;

    return type === 'bonus';
  }
  get period(): any {
    if (this.user) {
      const user = this.user.info;
      const period_start = user.current_period_start;
      const period_end = user.current_period_end;

      const start = moment(period_start).format('DD MMMM YYYY');
      const end = moment(period_end).format('DD MMMM YYYY');

      return `${start} - ${end}`;
    }
    return null;
  }
  get currentStatus(): any {
    return this.user.info.status;
  }
  get nextStatus(): any {
    return this.user.info.nextStatus;
  }
  get sum(): number | null {
    return this.user.info.sumInvoiceGeneral;
  }
  get percentage(): number {
    if (!this.nextStatus) {
      return 100;
    }
    const currentSum = this.user.info.sumInvoiceGeneral;
    const nextSum = this.nextStatus.criteria_bonus;

    const percent = (currentSum / nextSum) * 100;
    return +percent.toFixed(2);
  }
  get currentBonuses(): number | null {
    return this.user.info.currentBonusesGeneral;
  }
  get spentBonuses(): number | null {
    return this.user.info.spentBonusesGeneral;
  }
  get nextBonuses(): number | null {
    return this.user.info.nextBonusesGeneral;
  }
  get currentCurrency(): number | null {
    if (this.currentBonuses) {
      return this.currentBonuses / 100;
    }
    return 0;
  }

  created() {
    this.getStatusesList({});
  }
}
