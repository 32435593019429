
// import Vue from "vue";
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'expansion-panel',
})
export default class ExpansionPanel extends Vue {
  /*COMPONENT LOCAL DATA*/
  isOpened = false;

  /*COMPONENT LOCAL METHODS*/
  toggleOpen(): void {
    this.isOpened = !this.isOpened;
  }
}
