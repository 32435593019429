
// @ is an alias to /src
import { Vue, Options } from 'vue-class-component';
import { Action, State } from 'vuex-class';
import { ModalStateComponent } from '@/store/modules/modal/types';

@Options({})
export default class ErrorModal extends Vue {
  @State('modal')
  modal!: ModalStateComponent;
  @Action('hideErrorModal', { namespace: 'modal' })
  hideErrorModal: any;
}
