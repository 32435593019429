import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d420fdf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-content notifications-content" }
const _hoisted_2 = { class: "content-header" }
const _hoisted_3 = { class: "heading" }
const _hoisted_4 = { class: "content-body" }
const _hoisted_5 = { class: "information-table" }
const _hoisted_6 = { class: "table-content" }
const _hoisted_7 = { class: "table-row notification-item" }
const _hoisted_8 = { class: "table-col date-col" }
const _hoisted_9 = { class: "table-col time-col" }
const _hoisted_10 = { class: "btn-wrapper" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('main_menu_notifications')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications.list, (notification) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$filters.date(notification.created_at)), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$filters.time(notification.created_at)), 1),
              _createElementVNode("p", {
                class: _normalizeClass([{ 'not-read': !notification.is_read }, "table-col text-col"])
              }, _toDisplayString(notification.text), 3)
            ]))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        (this.user.info.unread_notifications && _ctx.notifications.pagination && _ctx.notifications.pagination.current_page != _ctx.notifications.pagination.last_page)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
              class: "primary-btn silver-btn notifications-next-button"
            }, _toDisplayString(_ctx.$t('notifications_button_next_page')), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}