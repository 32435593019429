
// @ is an alias to /src
import { Prop, Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { ModalStateComponent } from '@/store/modules/modal/types';
import { UserStateComponent } from '@/store/modules/user/types';
import { Validator } from '@/utils';

type StepType = 'ENTER_EMAIL' | 'VERIFY_EMAIL' | 'ENTER_CODE';

@Options({})
export default class EmailModal extends Vue {
  @State('modal')
  modal!: ModalStateComponent;
  @State('user')
  user!: UserStateComponent;

  @Getter('errorCode', { namespace: 'user' })
  errorCode!: string;

  @Action('showSuccessModal', { namespace: 'modal' })
  showSuccessModal: any;
  @Action('hideEmailModal', { namespace: 'modal' })
  hideEmailModal: any;
  @Action('setEmail', { namespace: 'user' })
  setEmail: any;
  @Action('verifyEmail', { namespace: 'user' })
  verifyEmail: any;
  @Action('getProfile', { namespace: 'user' })
  getProfile: any;

  private email: string = '';
  private stepName: StepType = 'ENTER_EMAIL';
  private isValidError: boolean = false;
  private validErrorMessage: string = '';
  private firstCheck: boolean = false;
  private serverError: string = '';
  private isServerError: boolean = false;
  private emailCode: string[] = ['', '', '', ''];
  private isCodeError: boolean = false;
  // get isDisabled () { return !Validator.email(this.email) && this.firstCheck };

  get fullCode() {
    return this.emailCode.join('');
  }

  @Watch('modal.emailModal.show', { immediate: true, deep: true })
  onShowModalChange(value: any) {
    // Some action
    // console.log('newVal',value)
    if (
      value &&
      this.stepName === 'ENTER_EMAIL' &&
      this.user.info.email &&
      !this.user.info.emailVerifiedAt
    ) {
      this.stepName = 'VERIFY_EMAIL';
    }
  }

  async sendEmailCode() {
    let email = this.user.info.email || this.email;
    if (!Validator.email(email)) {
      // this.firestCheck = true;
      this.isValidError = true;
    } else {
      if (!this.user.isPending) {
        await this.setEmail({ email });
        if (this.user.hasError) {
          this.triggerUnsuccessfulMessage(this.errorCode);
        } else {
          if (this.stepName !== 'VERIFY_EMAIL') {
            this.stepName = 'VERIFY_EMAIL';
            await this.getProfile();
          }
        }
      }
    }
  }

  goToEnterCodeStep() {
    this.stepName = 'ENTER_CODE';
  }

  async sendCode() {
    if (!this.user.isPending) {
      if (this.fullCode && this.fullCode.length === 4) {
        await this.verifyEmail({ code: this.fullCode });
        if (this.user.hasError) {
          this.isCodeError = true;
          this.triggerUnsuccessfulMessage(this.errorCode);
        } else {
          this.hideEmailModal();
          this.showSuccessModal({
            title: this.$t('email_modal_title_success'),
            message: this.$t('email_modal_text_success'),
          });
          await this.getProfile();
        }
      } else {
        this.isCodeError = true;
        this.triggerUnsuccessfulMessage('ERR2001');
      }
    }
  }

  triggerUnsuccessfulMessage(errorCode?: string) {
    this.isServerError = true;
    let errorMsg = '';
    switch (errorCode) {
      case 'ERR6000':
        errorMsg = this.$t('general_auth_error');
        break;
      case 'ERR1613':
        this.validErrorMessage = this.$t('email_modal_error_email_uniq');
        break;
      case 'ERR2001':
        this.serverError = this.$t('email_modal_error');
        break;
      case 'ERR2002':
        this.serverError = this.$t('email_modal_error_code_expired');
        break;
      case 'ERR2003':
        this.serverError = this.$t('email_modal_error_code_used');
        break;
      // 'wrong_code' => 'ERR2001',
      // 'expired_code' => 'ERR2002',
      // 'used_code' => 'ERR2003',
      default:
        break;
    }

    // this.serverError = errorMsg;
  }

  handleEmailInputChange() {
    if (this.isValidError) {
      this.isValidError = false;
      this.validErrorMessage = '';
    }
  }

  handleInputDigit(number: number) {
    if (this.emailCode[number - 1] && number < 4) {
      (this.$refs[`digit_${number + 1}`] as HTMLInputElement)?.focus();
    }
    if (this.isCodeError === true) {
      this.isCodeError = false;
    }
  }

  handleDeleteDigit(number: number) {
    if (!this.emailCode[number - 1] && number > 1) {
      (this.$refs[`digit_${number - 1}`] as HTMLInputElement)?.focus();
    }
    if (this.isCodeError === true) {
      this.isCodeError = false;
    }
  }

  handleSecondDigit(event: KeyboardEvent, number: number) {
    if (this.emailCode[number - 1] && !isNaN(+event.key) && number < 4) {
      this.emailCode[number] = event.key;
      (this.$refs[`digit_${number + 1}`] as HTMLInputElement)?.focus();
    }
    if (this.isCodeError === true) {
      this.isCodeError = false;
    }
  }
}
