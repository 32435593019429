import { ActionTree } from 'vuex';
import { ModalState } from './types';
import { RootState } from '@/store/types';
import { Actions } from '@/utils';

export const actions: ActionTree<ModalState, RootState> = {
  showErrorModal({ commit }, { title, message }): any {
    commit('showErrorModal', { title, message });
  },
  hideErrorModal({ commit, dispatch }, { delay = 350 }): any {
    commit('hideErrorModal');
    setTimeout(
      Actions.timeoutActionEvent.bind(null, dispatch, 'cleanErrorModalContent'),
      delay
    );
  },
  cleanErrorModalContent({ commit }): any {
    commit('cleanErrorModalContent');
  },
  showSuccessModal({ commit }, { title, message }): any {
    commit('showSuccessModal', { title, message });
  },
  hideSuccessModal({ commit, dispatch }, { delay = 350 }): any {
    commit('hideSuccessModal');
    setTimeout(
      Actions.timeoutActionEvent.bind(
        null,
        dispatch,
        'cleanSuccessModalContent'
      ),
      delay
    );
  },
  cleanSuccessModalContent({ commit }): any {
    commit('cleanSuccessModalContent');
  },

  showEmailModal({ commit }): any {
    commit('showEmailModal');
  },
  hideEmailModal({ commit }): any {
    commit('hideEmailModal');
  },

  showVoucherModal({ commit }): any {
    commit('showVoucherModal');
  },
  hideVoucherModal({ commit }): any {
    commit('hideVoucherModal');
  },

  showLimitModal({ commit }, { user }): any {
    commit('showLimitModal', { user });
  },
  hideLimitModal({ commit }): any {
    commit('hideLimitModal');
  },

  showBonusesModal({ commit }): any {
    commit('showBonusesModal');
  },
  hideBonusesModal({ commit }): any {
    commit('hideBonusesModal');
  },

  showWelcomeModal({ commit }): any {
    if (!sessionStorage.getItem('isShowedModal')) {
      commit('showWelcomeModal');
      sessionStorage.setItem('isShowedModal', JSON.stringify(true));
    }
  },
  hideWelcomeModal({ commit }): any {
    commit('hideWelcomeModal');
  },
  showCardsModal({ commit }, { block }): any {
    commit('showCardsModal', block);
  },
  hideCardsModal({ commit }): any {
    commit('hideCardsModal');
  },
};
