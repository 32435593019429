import { ActionTree } from 'vuex';
import { NotificationState } from './types';
import { RootState } from '@/store/types';
import Api from '@/api';

export const actions: ActionTree<NotificationState, RootState> = {
  getList({ commit }, { params }): any {
    commit('setPending');
    return Api.notification.getList(params).then(
      (response) => {
        const payload = response && response.data;
        commit('unsetPending');
        commit('unsetError');
        commit('setList', payload);
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
  getUnReadList({ commit }): any {
    commit('setPending');
    return Api.notification.getUnReadList().then(
      (response) => {
        const payload = response && response.data;
        commit('unsetPending');
        commit('unsetError');
        commit('setList', payload);
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
  setPageRead({ commit }): any {
    commit('setPending');
    return Api.notification.setPageRead().then(
      (response) => {
        commit('unsetPending');
        commit('unsetError');
      },
      (error) => {
        commit('unsetPending');
        commit('setError', error.response.data);
      }
    );
  },
};
