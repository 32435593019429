import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import auth from './modules/auth';
import user from './modules/user';
import feedback from './modules/feedback';
import voucher from './modules/voucher';
import invoice from './modules/invoice';
import status from './modules/status';
import notification from './modules/notification';
import discount from './modules/discount';
import promocode from './modules/promocode';

export const tokenHandler = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  // Do something before request is sent
  // console.log(config);
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

export const errorHandler = (error: any) => {
  // Do something with request error
  // console.log(error)
  return Promise.reject(error);
};

const instance: AxiosInstance = axios.create({
  baseURL: '/api/v1',
  headers: {
    // Authorization: token || "NONE",
    post: {
      'Content-Type': 'application/json', //
    },
    put: {
      'Content-Type': 'application/json',
    },
  },
});

instance.interceptors.request.use(tokenHandler, errorHandler);

export default {
  axios: instance,
  auth: auth(
    instance,
    process.env.VUE_APP_CLIENT_ID,
    process.env.VUE_APP_CLIENT_SECRET
  ),
  user: user(instance),
  feedback: feedback(instance),
  voucher: voucher(instance),
  invoice: invoice(instance),
  status: status(instance),
  notification: notification(instance),
  discount: discount(instance),
  promocode: promocode(instance),
};
