
// import Vue from "vue";
// import Component from "vue-class-component";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'check-details',
})
export default class CheckDetails extends Vue {
  @Prop({ type: Object, default: null, required: true }) 'details': null;
}
