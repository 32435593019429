
// @ is an alias to /src
import { Getter, State } from 'vuex-class';
import { Vue, Options } from 'vue-class-component';
import { UserStateComponent } from '@/store/modules/user/types';
import Component from 'vue-class-component';
import Alert from '@/components/UiComponents/Alerts/Alert';
import ExpansionPanel from '@/components/UiComponents/ExpansionPanel';

@Options({
  name: 'personal-data',
  components: {
    Alert,
    ExpansionPanel,
  },
})
export default class PersonalData extends Vue {
  @State('user')
  user!: UserStateComponent;

  @Getter('isLegalProfile', { namespace: 'user' })
  isLegalProfile!: string;

  get is_cardholders() {
    return this.user.info.cardholders.length;
  }

  // PARSING STRINGS
  humanize_null(payload: string | number | null) {
    return payload ? payload : this.$t('no_data');
  }

  get name() {
    const name = this.user.info.firstName;
    return this.humanize_null(name);
  }
  get surname() {
    const surname = this.user.info.lastName;
    return this.humanize_null(surname);
  }
  get phone() {
    const name = this.user.info.phone;
    return this.humanize_null(name);
  }
  get email() {
    const email = this.user.info.email;
    return this.humanize_null(email);
  }
  get barcode() {
    const barcode = this.user.info.barcode;
    return this.humanize_null(barcode);
  }
  get store_name() {
    const store_name = this.user.info.store_name;
    return this.humanize_null(store_name);
  }
  // PARSING STRINGS
}
