
// import Vue from "vue";
// import Component from "vue-class-component";
import { Vue, Options } from 'vue-class-component';
import ExpansionPanel from '@/components/UiComponents/ExpansionPanel';
import CheckItem from '@/components/CustomComponents/Check/Item';
import CheckDetails from '@/components/CustomComponents/Check/Details';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';
import { State } from 'vuex-class';
import { UserStateComponent } from '@/store/modules/user/types';

@Options({
  name: 'check',
  components: {
    ExpansionPanel,
    CheckItem,
    CheckDetails,
  },
})
export default class Check extends Vue {
  @Prop({ type: Object, default: {}, required: true }) 'check'!: {
    details: any;
    owner_cardholder_id: any;
  };

  @State('user')
  user!: UserStateComponent;

  formatDate(date: 'String' | 'Object') {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  get isLegal() {
    return this.check.owner_cardholder_id == this.user.info.cardholder_id;
  }

  get isCheckDetails() {
    const details = this.check.details;
    return details && details.length ? details : false;
  }
}
