import { GetterTree } from 'vuex';
import { VoucherState } from './types';
import { RootState } from '@/store/types';
import { Sort } from '@/utils';

export const getters: GetterTree<VoucherState, RootState> = {
  sortedFaceValues(state) {
    return [...state.faceValueList].sort(Sort.ascNumber('amount'));
  },
};
