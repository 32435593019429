import { AxiosInstance } from 'axios';
import { LoginData, AuthData } from '../types';

export default (
  $axios: AxiosInstance,
  client_id: string = '',
  client_secret: string = ''
) => ({
  login(email: string, password: string) {
    return $axios.post('/test/login', { email, password });
  },

  logout() {
    return $axios.post('/test/logout');
  },

  // authBySMS(login: string, code?: string) {
  authBySMS(loginData: LoginData) {
    return $axios.post('/auth/sms', { ...loginData });
  },

  // authGetToken(login: string, verification_code: string, code?: string) {
  authGetToken(authData: AuthData) {
    return $axios.post('/auth/token', {
      client_id,
      client_secret,
      grant_type: 'phone_verification_code',
      // login,
      // verification_code
      ...authData,
    });
  },
});
