import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './locales';
import moment from 'moment';
import VTooltipPlugin from 'v-tooltip';

const app = createApp(App);

app.config.globalProperties.$filters = {
  date(date: string) {
    return date ? moment(date).format('DD.MM.YYYY') : '';
  },
  time(date: string) {
    return date ? moment(date).format('HH:mm') : '';
  },
};

app.use(i18n).use(router).use(store).use(VTooltipPlugin).mount('#app');

// Vue.config.productionTip = false;

// Vue.use(VTooltipPlugin)

// Vue.filter("date", function (date: string) {
//   return date ? moment(date).format("DD.MM.YYYY") : "";
// });

// Vue.filter("time", function (date: string) {
//   return date ? moment(date).format("HH:mm") : "";
// });

// new Vue({
//   i18n,
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");
