import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bordered-wrapper bordered-wrapper--bottom" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "stack--bottom--xl mb-4 mb-lg-0" }
const _hoisted_5 = { class: "entity-key entity-key--xl" }
const _hoisted_6 = { class: "text--gray text-xl" }
const _hoisted_7 = { class: "text--black heading-4" }
const _hoisted_8 = { class: "entity-key entity-key--xl" }
const _hoisted_9 = { class: "text--gray text-xl" }
const _hoisted_10 = { class: "text--black heading-4" }
const _hoisted_11 = { class: "entity-key entity-key--xl" }
const _hoisted_12 = { class: "text--gray text-xl" }
const _hoisted_13 = { class: "text--black heading-4" }
const _hoisted_14 = { class: "page-subheading" }
const _hoisted_15 = {
  key: 1,
  class: "stack--bottom--lg"
}
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-lg-5" }
const _hoisted_18 = { class: "entity-key entity-key--xl" }
const _hoisted_19 = { class: "text--gray text-xl" }
const _hoisted_20 = { class: "text--primary text-xl medium" }
const _hoisted_21 = { class: "col-lg-7" }
const _hoisted_22 = { class: "entity-key entity-key--xl" }
const _hoisted_23 = { class: "text--gray text-xl" }
const _hoisted_24 = { class: "text--primary text-xl medium" }
const _hoisted_25 = { class: "stack--top--lg mt-3" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-lg-5 mb-3" }
const _hoisted_28 = { class: "entity-key entity-key--xl" }
const _hoisted_29 = { class: "text--gray text-xl" }
const _hoisted_30 = { class: "text--primary text-xl medium" }
const _hoisted_31 = { class: "col-lg-7 mb-3" }
const _hoisted_32 = { class: "entity-key entity-key--xl" }
const _hoisted_33 = { class: "text--gray text-xl" }
const _hoisted_34 = { class: "text--primary text-xl medium" }
const _hoisted_35 = { class: "col-lg-5 mb-3" }
const _hoisted_36 = { class: "entity-key entity-key--xl" }
const _hoisted_37 = { class: "text--gray text-xl" }
const _hoisted_38 = { class: "text--primary text-xl medium" }
const _hoisted_39 = { class: "col-lg-7 mb-3" }
const _hoisted_40 = { class: "entity-key entity-key--xl" }
const _hoisted_41 = { class: "text--gray text-xl" }
const _hoisted_42 = { class: "text--primary text-xl medium" }
const _hoisted_43 = { class: "col-lg-5" }
const _hoisted_44 = { class: "entity-key entity-key--xl" }
const _hoisted_45 = { class: "text--gray text-xl" }
const _hoisted_46 = { class: "text--primary text-xl medium" }

export function render(_ctx, _cache) {
  const _component_alert = _resolveComponent("alert")
  const _component_expansion_panel = _resolveComponent("expansion-panel")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('company_name')) + ":", 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.name), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('company_legal_address')) + ":", 1),
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.address), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('company_mail_address')) + ":", 1),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.address), 1)
            ])
          ])
        ])
      ])
    ]),
    (_ctx.isLegalProfile)
      ? (_openBlock(), _createBlock(_component_alert, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t('cardholders_data')), 1),
    (_ctx.is_cardholders)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.info.cardholders, (cardholder, cardholder_index) => {
            return (_openBlock(), _createBlock(_component_expansion_panel, {
              class: "full-row full-row--xxl background--smoky",
              key: 'cardholder' + cardholder_index
            }, {
              body: _withCtx(() => [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('cardholders_card_number')), 1),
                      _createElementVNode("span", _hoisted_20, "№ " + _toDisplayString(cardholder.barcode), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('cardholders_tc_data')) + ":", 1),
                      _createElementVNode("span", _hoisted_24, _toDisplayString(cardholder.store_name), 1)
                    ])
                  ])
                ])
              ]),
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t('cardholders_name')) + ":", 1),
                        _createElementVNode("span", _hoisted_30, _toDisplayString(cardholder.firstName), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('cardholders_surname')) + ":", 1),
                        _createElementVNode("span", _hoisted_34, _toDisplayString(cardholder.lastName), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$t('cardholders_limit')) + ":", 1),
                        _createElementVNode("span", _hoisted_38, _toDisplayString(cardholder.bonuses), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t('cardholders_phone')) + ":", 1),
                        _createElementVNode("span", _hoisted_42, _toDisplayString(cardholder.phone), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("div", _hoisted_44, [
                        _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t('cardholders_mail')) + ":", 1),
                        _createElementVNode("span", _hoisted_46, _toDisplayString(cardholder.email), 1)
                      ])
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}