import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { UserState } from './types';
import combainer from '@/store/mixins/vuexCombainer';
import errorHandler from '@/store/mixins/errorHandler';
import pendingHandler from '@/store/mixins/pendingHandler';

export const state: UserState = {
  info: {
    clientType: '',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    barcode: '',
    state: '',
    phone: '',
    emailVerifiedAt: null,
    store: '',
    store_name: '',
    legalPerson: '',
    isAgree: null,
    isBlocked: null,
    cardIsBlocked: false,
    cardholder_id: null,
    customer_id: null,
    limit: null,
    sumInvoiceGeneral: 0,
    nextBonusesGeneral: null,
    nextBonuses: null,
    spentBonusesGeneral: null,
    spentBonuses: null,
    currentBonusesGeneral: null,
    currentBonuses: null,
    bonusesGeneral: null,
    bonuses: null,
    company: {
      companyId: null,
      companyName: '',
      email: '',
      house: '',
      area: '',
      district: '',
      town: '',
      street: '',
    },
    discountType: {
      created_at: '',
      id: null,
      key: '',
      name: '',
      updated_at: '',
    },
    nextPeriodDiscountType: {
      created_at: '',
      id: null,
      key: '',
      name: '',
      updated_at: '',
    },
    status: {
      client_type: '',
      coefficient_discount: 0,
      created_at: '',
      criteria_bonus: 0,
      disabled_coefficient_discount: false,
      disabled_discount: false,
      discount: 0,
      dnr: '',
      duration: '',
      id: 0,
      is_active: true,
      is_default: true,
      name: '',
      start_at: '',
      updated_at: '',
    },
    nextStatus: {
      client_type: '',
      coefficient_discount: 0,
      created_at: '',
      criteria_bonus: 0,
      disabled_coefficient_discount: false,
      disabled_discount: false,
      discount: 0,
      dnr: '',
      duration: '',
      id: 0,
      is_active: true,
      is_default: true,
      name: '',
      start_at: '',
      updated_at: '',
    },
    cardholders: [],
    current_period_start: '',
    current_period_end: '',
    unread_notifications: 0,
  },
};

const namespaced = true;

const user = combainer(
  {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  },
  errorHandler(),
  pendingHandler()
);

export default user;
