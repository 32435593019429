
// @ is an alias to /src
import { Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { Action, Getter, State } from 'vuex-class';
import { ModalStateComponent } from '@/store/modules/modal/types';
import { UserStateComponent } from '@/store/modules/user/types';

@Options({})
export default class LimitChangeModal extends Vue {
  @State('modal')
  modal!: ModalStateComponent;
  @State('user')
  user!: UserStateComponent;

  @Action('showLimitModal', { namespace: 'modal' })
  showLimitModal: any;
  @Action('hideLimitModal', { namespace: 'modal' })
  hideLimitModal: any;

  @Action('changeLimit', { namespace: 'user' })
  changeLimit: any;

  @Action('showSuccessModal', { namespace: 'modal' })
  showSuccessModal: any;

  @Action('getProfile', { namespace: 'user' })
  getProfile: any;

  @Getter('errorCode', { namespace: 'user' })
  errorCode!: string;

  private userId: number | null = null;

  @Watch('modal.limitModal.show', { deep: true })
  checkShowValue(val: any) {
    if (val) {
      this.userId =
        this.modal.limitModal.user && this.modal.limitModal.user.id
          ? this.modal.limitModal.user.id
          : null;
      this.limit = '';
    } else {
      this.userId = null;
    }
  }

  limit: string = '';
  validErrorMessage: string = '';

  changeLimitInput() {
    this.validErrorMessage = '';
  }
  errorHandler(errorCode: string) {
    switch (errorCode) {
      case 'LIMIT':
        this.validErrorMessage = this.$t('settings_modal_error_limit');
        break;
      case 'VALID':
        this.validErrorMessage = this.$t('settings_modal_validation_error');
        break;

      default:
        this.validErrorMessage = this.$t('settings_modal_server_error');
        break;
    }
  }

  validForChange() {
    return (
      this.limit &&
      this.user.info.bonusesGeneral &&
      this.user.info.bonusesGeneral > +this.limit
    );
  }

  async change() {
    if (this.validForChange()) {
      await this.changeLimit({ limit: this.limit, userId: this.userId });
      if (this.errorCode || this.user.hasError) {
        this.errorHandler(this.errorCode);
      } else {
        this.showSuccessModal({
          title: this.$t('settings_modal_title_ready'),
          message: this.$t('settings_modal_text_limitready'),
        });
        this.hideLimitModal();
        await this.getProfile();
        this.userId = null;
      }
    } else {
      if (!this.limit) {
        this.errorHandler('VALID');
      }
      if (
        this.limit &&
        this.user.info.bonusesGeneral &&
        this.user.info.bonusesGeneral <= +this.limit
      ) {
        this.errorHandler('LIMIT');
      }
    }
  }
}
