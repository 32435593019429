
// import Vue from "vue";
// import Component from "vue-class-component";
import { Vue, Options } from 'vue-class-component';
import { createApp } from 'vue';
import { Prop } from 'vue-property-decorator';
import { Parthner, Promocode } from '@/store/modules/promocode/types';
// import VueClipboard from 'vue3-clipboard'
// const VueClipboard = require('vue3-clipboard');
import { VueClipboard } from '@soerenmartius/vue3-clipboard';
// TODO: CUT SINGLE MOMENT IMPORTS
import moment from 'moment';
const app = createApp({});
app.use(VueClipboard);
// Vue.use(VueClipboard);

@Options({
  name: 'promocode',
})
export default class Check extends Vue {
  // PROPERTIES
  @Prop({ type: Object, default: () => ({}) }) 'partner'!: Parthner;
  @Prop({ type: Object, default: () => ({}) }) 'promocode'!: Promocode;

  // LOCAL VARIABLES
  isHint = false;

  // LOCAL GETTERS
  get background() {
    const background = this.partner.image_logo;
    return `url(${background})`;
  }

  get start() {
    const start = this.promocode.date_start;
    return moment(start).format('DD.MM.YY');
  }

  get end() {
    const start = this.promocode.date_end;
    return moment(start).format('DD.MM.YY');
  }

  get hintOptions() {
    return {
      content: this.$t('components.promocode.copy_hint'),
      triggers: [],
      shown: this.isHint,
    };
  }

  // METHODS
  onCodeCopied() {
    this.isHint = true;
    setTimeout(() => {
      this.isHint = false;
    }, 2000);
  }
}
