import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "check-item__info" }
const _hoisted_2 = { class: "check-item__info__row" }
const _hoisted_3 = { class: "entity-key--md" }
const _hoisted_4 = { class: "text--gray" }
const _hoisted_5 = { class: "text--black medium" }
const _hoisted_6 = { class: "entity-key--md" }
const _hoisted_7 = { class: "text--gray" }
const _hoisted_8 = { class: "text--black medium" }
const _hoisted_9 = { class: "check-item__info__row" }
const _hoisted_10 = {
  key: 0,
  class: "entity-key--md"
}
const _hoisted_11 = { class: "text--gray" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('invoice_item_trade_center_data')) + ":", 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.check.store_name), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('invoice_item_total_sum')) + ":", 1),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.check.invoice_amount_gsp), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.check.vouchers_epc.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('invoice_item_voucher_number')) + ":", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.check.vouchers_epc, (voucher_epc, voucher_index) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "text--black medium",
                key: 'voucher' + voucher_index
              }, _toDisplayString(voucher_epc), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}