import { AxiosInstance } from 'axios';
import { ApiUtils, Params } from '@/utils';
import { VoucherGenerateRequestData } from '../types';

export default ($axios: AxiosInstance) => ({
  getFaceValueList(params: Params = {}) {
    return $axios.get(`/face-value${ApiUtils.toQueryString(params)}`);
  },
  getList(params: Params = {}) {
    return $axios.get(`/voucher${ApiUtils.toQueryString(params)}`);
  },
  generate(genData: VoucherGenerateRequestData) {
    const { faceValue, userId } = genData;
    return $axios.get(
      `/voucher/generate/${faceValue}${userId ? `/${userId}` : ''}`
    );
  },
  deactivate(voucherId: number) {
    return $axios.put(`/voucher/${voucherId}/deactivate/`);
  },
});
