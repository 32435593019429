import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { ModalState } from './types';
import combainer from '@/store/mixins/vuexCombainer';
import errorHandler from '@/store/mixins/errorHandler';
import pendingHandler from '@/store/mixins/pendingHandler';

export const state: ModalState = {
  errorModal: {
    show: false,
    title: '',
    message: '',
  },
  successModal: {
    show: false,
    title: '',
    message: '',
  },
  emailModal: {
    show: false,
  },
  voucherModal: {
    show: false,
  },
  limitModal: {
    show: false,
    user: null,
  },
  bonusesModal: {
    show: false,
  },
  welcomeModal: {
    show: false,
  },
  cardsModal: {
    show: false,
    block: false,
  },
};

const namespaced = true;

const feedback = combainer(
  {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  },
  errorHandler(),
  pendingHandler()
);

export default feedback;
