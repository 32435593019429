import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-content" }
const _hoisted_2 = { class: "content-header" }
const _hoisted_3 = { class: "heading" }
const _hoisted_4 = { class: "content-body" }
const _hoisted_5 = { class: "tab-links mb-5" }
const _hoisted_6 = { class: "loyalty-pages" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "topWarningBanner"),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('profile_title_loyalty')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: "/account/loyalty/basic",
          class: "tab-links_link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('loyalty_tab_general')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/account/loyalty/promo-codes",
          class: "tab-links_link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('loyalty_tab_promocodes')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/account/loyalty/invoices",
          class: "tab-links_link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('loyalty_tab_invoices')), 1)
          ]),
          _: 1
        }),
        (_ctx.isLegalProfile)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: "/account/loyalty/settings",
              class: "tab-links_link"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('loyalty_tab_settings')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}