
// @ is an alias to /src
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

@Options({})
export default class LoginErrorModal extends Vue {
  @Prop({ default: '' }) readonly errorHeadTitle!: string;
  @Prop({ default: '' }) readonly errorWarningText!: string;
  @Prop({ default: '' }) readonly errorWarningAppeal!: string;
  @Prop({ default: '' }) readonly errorWarningMessage!: string;
  @Prop({ default: '' }) readonly errorWarningMessagesListParagraph!: string;
  @Prop({
    default: () => {
      return [];
    },
  })
  readonly errorWarningMessagesList!: [];
  @Prop({ default: '' })
  readonly errorWarningMessagesAfterListParagraph!: string;
  @Prop({ default: '' }) readonly contactPhone!: string;
  @Prop({ default: '' }) readonly contactEmail!: string;
  @Prop({ default: '' }) readonly schedule!: string;
  // @Prop(Function) readonly handleOK: () => {} | undefined;

  get phoneLink() {
    return `tel:${this.contactPhone.trim().replace(/ /g, '')}`;
  }
  get emailLink() {
    return `mailto:${this.contactEmail || ''}`;
  }

  handleOKTrigger() {
    this.$emit('handleOK');
  }
}
