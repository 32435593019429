
// @ is an alias to /src
import { Vue, Options } from 'vue-class-component';
import { Formatter, Validator } from '@/utils';

@Options({
  name: 'profile-page',
})
export default class ProfilePage extends Vue {
  phoneFormatter(phone: string) {
    return Validator.phoneNumber(phone) ? phone : Formatter.phone(phone);
  }
}
