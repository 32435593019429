
// @ is an alias to /src
import { Mixins, Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import { Action } from 'vuex-class';

@Options({
  name: 'navigation',
})
export default class Navigation extends Vue {
  @Prop({ type: String, default: '' }) readonly 'username': string;
  @Prop({ type: Number || String, default: 0 }) readonly 'unread': string;
  @Action('logout', { namespace: 'auth' })
  logout: any;

  minimized = false;
  public get navigation(): any {
    return [
      {
        title: this.$t('main_menu_profile'),
        route: '/account/profile',
        icon: 'icon-profile',
      },
      {
        title: this.$t('main_menu_loyalty'),
        route: '/account/loyalty/',
        icon: 'icon-ic_card',
      },
      {
        title: this.$t('main_menu_notifications'),
        route: '/account/notifications',
        icon: 'icon-feedback',
        unread_count: this.unread,
      },
    ];
  }
  toggle_drawer() {
    this.minimized = !this.minimized;
  }
  handleLogout() {
    this.logout();
    this.$router.push({ path: '/login/person' });
  }
}
