import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-view-wrapper" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "page-heading" }
const _hoisted_6 = { class: "tab-links mb-5" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('profile_title_profile')), 1),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: "/account/profile/personal_data",
                "active-class": "active",
                class: "tab-links_link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('profile_subtitle_personal')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_router_link, {
                to: "/account/profile/company_data",
                "active-class": "active",
                class: "tab-links_link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('profile_subtitle_company')), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_router_view)
          ])
        ])
      ])
    ])
  ]))
}